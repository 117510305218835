const LanguageData = {
  TR: {
    Global: {
      Header: {
        homepage: 'Anasayfa',
        about1: 'S&S MED',
        about2: 'Hakkında',
        aboutTransplant1: 'Saç Ekimi',
        aboutTransplant2: 'Hakkında',
        clinic: 'Klinik',
        onlineReservation1: 'Online',
        onlineReservation2: 'Rezervasyon',
        contact: 'İletişim',
        institutional: 'Kurumsal',
        hairLoss: 'Saç Dökülmesi Nedenleri',
        medicalTreatment: 'Tıbbi Tedavi',
        fue: 'FUE Saç Ekimi',
        eyebrow: 'Kaş Ekimi',
        beard: 'Sakal / Bıyık Ekimi',
        postOperation: 'Operasyon Sonrası Talimatlar',
        fueInner: {
          manuel: 'Manuel Fue Saç Ekimi',
          micromotor: 'Micromotor Fue Saç Ekimi',
          unshaved: 'Uzun/Tıraşsız Manuel FUE',
          leteral: 'Lateral Slit Saç Ekimi',
          dhi: 'DHİ Saç Ekimi',
          sedasyon: 'Sedasyonlu Saç Ekimi',
        },
      },
    },
    Home: {
      HeadInfo: {
        title: 'YENİLİĞE VE DÖNÜŞÜME HAZIR MISINIZ?',
        paragraph1: ` Merkezimizde uzman kadromuzun uyguladığı FUE tekniği (Manuel Fue
                  ve/veya Mikromotor FUE) ile saç, sakal, bıyık ve kaş ekimi, ekim
                  yaptıracak kişinin özelliklerine göre tıraşlı ve/veya tıraşsız
                  yapılmaktadır. Saç, sakal, bıyık ve kaş nakli, extraction (greft/saç
                  kökü-folikül çıkarma işlemi), insizyon (greftlerin ekileceği alana
                  kesi/kanal açılması işlemi) ve implantasyon (ekim işlemi) olmak üzere
                  üç adımda sonuçlandırılmaktadır.`,
        paragraph2: ` Saç, Sakal, Bıyık ve Kaş ekimi operasyonunda hangi FUE tekniğinin
                  uygulanacağına hastalarımızla birlikte karar veriyoruz.`,
      },
      Fue: {
        title: 'FUE YÖNTEMİ',
        subTitle1: 'Manuel Fue Tekniği',
        subTitle2: 'Mikromotor Fue Tekniği',
        paragraph1: `Saç Ekimi, Sakal Ekimi, Bıyık Ekimi ve Kaş Ekimi işlemi içinde
        önemli unsurlar içeren kompleks bir işlemdir. Ekim işleminin
        olumlu sonuçlar vermesi; hasta özellikleri, ince tasarım, greft
        çıkarma, greftin korunması, kesi, ekim, operasyon sonrası bakım ve
        tamamlayıcı tedavilerin dikkatli bir şekilde uygulanmasına
        bağlıdır.`,
        paragraph2: `Her hasta, deneyimli kadromuz tarafından gelişmiş teknik
        ekipmanlar kullanılarak dikkatli bir şekilde analiz edilir ve saç
        nakli süreci belirlenir.`,
        paragraph3: `Her hastaya operasyon öncesi kan analizi ve elektrokardiyogram
        yapılmaktadır.`,
        paragraph4: `Operasyon süresince gerekli anestezik işlemler merkezimizde görev
        yapan anestezi uzmanımız tarafından belirlenir ve uygulanır.`,
        paragraph5: `Merkezimizde yüksek kaliteli cerrahi malzemeler kullanılmakta ve
        cerrahi malzemelerin kontrolü ve sterilizasyonu düzenli olarak
        yapılmaktadır.`,
        paragraph6: `Grefti etkileyen parametreler dikkatli bir şekilde en son
        teknoloji ile kontrol edilir. Greftler hypotermosol ve atp
        sıvılarında bekletilerek uzun süre ile canlı kalması sağlanır.
        Böylelikle greft kaybı yaşanmaz.`,
        paragraph7: `Operasyon süresince merkezimizde görev yapan tercümanımız hastaya
        eşlik etmektedir.`,
      },
    },
    About: {
      title: 'S&S MED HAKKINDA',
      paragraph1: `S&S MED, sağlık ve saç ekimi alanındaki tecrübelerini
        birleştirmiş deneyimli uzman ekipleri ile anlaşmalı olduğu
        Atakent Acıbadem Hastanesi’nde hasta memnuniyeti odaklı hizmet
        sunan bir sağlık kuruluşudur.`,
      paragraph2: `Merkezimizin temel prensibi, uzman ekibimiz tarafından saç ekimi
        ve saç dökülmesi tedavileri alanında uluslararası arenadaki
        gelişmeler sürekli takip edilerek, etik kurallar çerçevesinde
        ekim yaptıracak kişinin özelliklerine göre tespit ve
        değerlendirme yapılarak, hasta için en uygun çözümün
        uygulanmasıdır.`,
      paragraph3: `Temel amacımız, optimum saç ekimi ile dolgun ve doğal bir
        görünüm yaratmaktır.`,
      paragraph4: `Alanında uzman kadromuz, her hastanın özelliğine ve tercihlerine
        göre farklı yöntem ve teknikleri uygulayabilmektedir.`,
      paragraph5: `Uzmanlarımız gerek ulusal gerekse uluslararası kongrelere (ISHRS
            2. Akdeniz Fue Workshop Madrid/İspanya 24-26 Mayıs 2013, ISHRS
            Avrupa Saç Ekim Semineri Brüksel/Belçika 13-15 Haziran 2014, 3.
            Akdeniz FUE Workshop İstanbul/Türkiye 25-27 Haziran 2015),
            katılım sağlamış ve etkin olarak görev almıştır. Saç nakli
            cerrahisi alanında 13 yıllık deneyim ve tecrübesiyle hizmet
            vermeye devam eden uzmanlarımız bu süreçte plastik cerrahlar
            eşliğinde katıldığı binlerce saç ekimi operasyonunun yanında
            kaş, bıyık ve sakal nakilleri operasyonlarına da katılmıştır.`,
    },
    SacDokulmesiNedenleri: {
      title1: 'SAÇ DÖKÜLMESİ',
      title2: 'VE NEDENLERİ',
      subTitle1Part1: `Erkek Tipi Saç Dökülmesi`,
      subTitle1Part2: `(Androjenik Alopecia)`,
      subTitle2: `Kadınlarda Saç Dökülmesi`,
      paragraph1: `Saç dökülmesinin en önemli nedeni genetik faktörlerdir. Ailede
        saç dökülmesi ve seyrelmesi 18 yaşından itibaren her on
        erkekten yedisini etkileyen, bayanlarda da daha az sıklıkta
        görülebilen, kişiyi fiziksel, ruhsal ve toplumsal açıdan
        yaralayan ciddi bir sorundur.`,
      paragraph2: `Saç dökülmesinin en sık görülen tipi androjenik saç
        dökülmesidir. Dökülmenin erken evrelerinde saç incelir
        (minyatürizasyon) ve pigmentini kaybederek rengi açılır ve
        güçsüz, cansız bir görünümü olur. Görülme yaşı günümüzde 18
        yaşlarına kadar gerileyen erkek tipi saç dökülmesi, alın saç
        çizgisinin geriye gitmesi ile başlar ve tüm erkeklerde saç
        seyrelmesi olarak kendini gösterir.`,
      paragraph3: `Androjenik tip saç dökülmesi
        belirli bir düzeye ulaştıktan sonra tek çözüm saç naklidir.
        Ensenin üst bölümü ve iki kulak arasındaki bölgedeki saçlar
        testosteron yıkım ürünlerine duyarsız olduğundan dökülmezler.
        Saç naklinde bu saçlar kullanılır.`,
      paragraph4: `Saç dökülmesi günümüzde artan bir oranda kadınlarda da görülen
        ve sosyal hayatı etkileyen ciddi bir sorundur. Günümüzde her
        10 kadından birinde saç dökülmesi sorunu görülmektedir. Saç
        dökülmesi kadınlarda genellikle tüm saçlı deriyi ilgilendiren
        bir seyrelme ve incelme şeklinde görülür.`,
      paragraph5: `Erkeklerdeki saç kaybının başlıca nedeni genetikken,
        kadınlarda gözlenen saç kaybının nedenleri; hormonel
        değişiklikler, tiroid sorunları gibi bazı hastalıklar,
        kansızlık, demir başta olmak üzere mineral eksiklikleri,
        stres, travmalar olabilmektedir.`,
      paragraph6: `Kadınlarda sistemik ve hormonal bazı etkenleri ekarte emek
        amacıyla saç dökülme nedenleri araştırılır. Kadınlardaki saç
        dökülmeleri nedenlerini teşhis ve tedavi etmek erkeklerde
        olduğu kadar kolay değildir.`,
    },
    SacDokulmesiTedavileri: {
      title1: `SAÇ DÖKÜLMESİ`,
      title2: `TEDAVİLERİ`,
      subInfo: `Kliniğimizde saç dökülmesini önlemeye yönelik farklı tedavi yöntemleri uygulanmaktadır.`,
      subTitle1: `PRP Saç Tedavisi`,
      paragraph1: `P.R.P. yani "Plateletten Zengin Plazma" kendi kanınızdan elde
        edilen trombositlerin özel işlemlerden geçirilerek saç nakli
        sırasında ve sonrasında ekim yapılan bölgeye ya da dökülme
        aşamalarında seyrekleşen bölgelere uygulanmasıdır.`,
      paragraph2: `PRP tedavisi uygulanan saç nakli hastalarında foliküllerin,
        ekilen alanda tutunma oranlarının arttığını ve operasyon
        sonrası yara iyileşmesinin hızlandığını gözlemlenmiştir.
        Genetik ya da mevsimsel sebeplerle seyrekleşmiş ancak tamamen
        dökülmemiş saçları olan erkek ya da kadınlarda tedavi amaçlı
        uygulanabilir.`,
      paragraph3: `Ayrıca 'Androjenik Areata' adı altında geçen bölgesel saç
        dökülmesi ‘saç kıran ‘hastalığında da başarılı sonuçlar
        alınmaktadır. PRP sistemi her saç nakli vakasına
        uygulanabilir. Kendi kanınızla yapılan bu işlem başka maddeler
        ihtiva etmediğinden tedavi sonrasında herhangi bir alerjik
        reaksiyon ya da enfeksiyon riski kesinlikle bulunmaz.`,
      paragraph4: `P.R.P yani "Plateletten Zengin Plazma" 1970' lerden beri tüm
        dünyada yara iyileştirmesini hızlandırmada kullanılan bir kan
        yan ürünüdür. P.R.P'nin bu etkisi Amerika'da FDA (Gıda ve İlaç
        İdaresi ) tarafından onaylanmıştır.`,
      subTitle2: `Saç Mezoterapisi`,
      paragraph5: `Saç mezoterapisi saç dökülmesini durdurabilmek ve cilt altında
        uyumakta olan saç köklerinin bir kısmını aktif hale getirerek
        canlanmasını sağlamak amacıyla, saçlı deriye uygulanan bir
        tedavi yöntemidir.`,
      paragraph6: `Saç mezoterapisi, dökülme nedeni belirlenerek, seçilen ilaç
        karışımlarının bölgesel olarak küçük dozlarda özel iğneler ve
        özel bir teknikle saçlı derinin derin dermis tabakasına
        enjekte edilmesidir. Bu özel aminoasit, vitamin ve mineral
        karışımı formül ile aktive olmuş kök hücrelerinin beslenmesi
        sağlamaktadır. Böylece kısa süreli değişiklikler yerine uzun
        dönemde kalıcı yapılandırmalar ile güçsüzleşmiş ve
        cansızlaşmış saçlar yeniden sağlıklı bir hale
        dönüştürülebilir, saçlı deri kan dolaşımı etkinleştirilebilir,
        lenfatik drenaj düzenlenebilir, collagen yapı
        güçlendirilebilir.`,
      paragraph7: `Saç dökülmesi tedavisi için, aktif duruma geçen tüm kök
        hücrelerin hızlanan metabolizmalarının beslenmesi için
        uygulanan mezoterapi seansları 15 dakika sürmekte ve
        uzmanlarımız tarafından uygulanmaktadir.`,
      subTitle3: `Finansterid –Medikal Tedavi`,
      paragraph8: `Finasterid bir 5α-redüktaz tip 2 inhibitörüdür. Bu
        testosteronun, dehidrotestosterona (DHT) dönüşümünü azaltır.`,
      paragraph9: `Finasterid kullanan kişilerin % 80’inde saç sayısı ve
        kalınlığında önemli artışlar gözlemlenmektedir. İncelen
        saçların kalınlaşmasını sağladığı gibi saç dökülmelerini de
        durdurur. Kaybedilmiş olan saçları geri getirmez. Vücutta
        kıllanmaya sebep olmaz.`,
      paragraph10: `Kullanılmaya başlamasını takriben 4-6 ayda etkisini göstermeye
        başlar. Maksimum etkiyi elde etmek için 2 yıl süre ile
        kullanılmalıdır. Etkisini korumak için sürekli kullanım
        önerilir.`,
      paragraph11: `Finasterid, incelmiş saçların mevcut olduğu her bölgede etkili
        olmakla birlikte, orta ve tepe bölgeler üzerinde daha
        etkilidir.`,
      paragraph12: `Finasterid için önerilen doz günde 1mg’dir. Bilinen hiçbir
        ilaç etkileşimi yoktur.`,
      paragraph13: `Finasterid, hamile kadınlar tarafından ve karaciğer hastalığı
        olan hastalar tarafından kullanılmamalıdır.`,
      paragraph14: `Finasteridin bildirilen yan etkileri şunlardır: Libido (%
            1.9), erektil disfonksiyon (% 1.3), ve ejakülasyon
            disfonksiyonun (% 1) belirtilen oranlarda azalması ya da
            artması. Bazı hastalarda, ilaç yan etkileri kullanımı
            kestikten sonra yaklaşık 3 ay süreyle devam etmektedir.
            Finasterid prostat spesifik antijen (PSA) seviyelerini
            azaltmak için çalışır ve nadiren jinekomastiye neden olabilir.
            Finasterid saç için FDA tarafından onaylanan ilk ilaçtır.
            Öncelikle, saç kaybını dengelemek, mevcut saçları korumak ve
            saç dökülmesini azaltmak için kullanılır.`,
      subTitle4: `Minoxidil – Medikal Tedavi`,
      paragraph15: `Günümüzde uygulanıyor olmakla birlikte kliniğimizce
            önerilmemektedir.`,
    },
    KasEkimi: {
      title: 'KAŞ EKİMİ',
      paragraph1: `Kaş ekimi uygulama yöntemleri saç ekimi ile aynıdır.`,
      paragraph2: `FUE yöntemiyle alınan saç kökleri özel pensetler yardımı ile
        seyrek olan veya hiç olmayan kaş bölgesine doğallık gözetilerek
        nakledilir.`,
      paragraph3: `Kaş ekimi ortalama 4/5 saat sürmektedir. Operasyondan sonra hasta
        günlük yaşamına devam edebilmektedir.`,
    },
    SakalEkimi: {
      title1: `SAKAL / BIYIK`,
      title2: `EKİMİ`,
      paragraph1: `Sakal ve/veya Bıyık ekimi uygulama yöntemleri saç ekimi ile
        aynıdır.`,
      paragraph2: `FUE yöntemiyle alınan saç kökleri özel pensetler yardımı ile sakal
        ve bıyık bölgesine nakledilir.`,
      paragraph3: `Yüz bölgesi hassas bir yapıya sahip olduğu için uygulamalar
        tecrübeli kişiler tarafından yapılmalıdır.`,
    },
    FooterForm: {
      title: `Ücretsiz Danışın`,
      name: `Ad Soyad`,
      email: 'E-Mail',
      phone: 'Telefon',
      nationality: 'Uyruğunuz',
      subject: 'Konu',
      message: 'Mesaj',
      send: 'Gönder',
    },
    Fue: {
      title1: 'FUE',
      title2: '(Follicular',
      title3: 'Unit Extraction)',
      top: {
        paragraph1:
          'FUE, saç ekiminde greftlerin çıkarılması aşamasında kullanılan yöntemi olup, saç nakli cerrahisi alanındaki son gelişmedir.',
        paragraph2:
          'FUE tekniğinde greftler mikro punchlar kullanılarak donör bölgesinden (saç alımı yapılacak iki kulak arasında kalan alan) alınır.',
        paragraph3:
          'FUE tekniği çok sayıda greftin, donör bölgesinde herhangi yara izi bırakmadan, optimum sayıda ve kalite de greftlerin tek tek alınması/extraction işlemidir. FUE tekniğinde saç derisinde herhangi bir kesi yapılmaz.',
        paragraph4:
          'FUE yöntemi, diğer tekniklere oranla daha fazla greft alınmasını/çıkarılmasını sağlar. FUE yöntemi, diğer yöntemlere oranla ekilecek greft sayısını artırır ve hastanın daha sık/yoğun bir saça kavuşmasına olanak sağlar.',
      },

      benefitTitle: 'FUE Yönteminin Sağladığı Faydalar',

      benefits: {
        left: [
          'Hiçbir dikiş gerektirmez ve kalıcı yara izi bırakmaz.',
          'Konforlu ve kısa süreli iyileşme süreci sağlar.',
          'Genel olarak ödem ve morluk oluşmaz.',
          'Operasyon sonrası ağrı neredeyse yoktur.',
        ],
        right: [
          'Operasyon sonrası activite daha az sınırlanır ve günlük hayata dönülür.',
          'Maksimum sayıda greft çıkarılabilir.',
          'Hasta dilediği uzunlukta veya kısalıkta saçını kullanabilir.',
        ],
      },
      info1: `FUE yöntemi ile saç köklerinin/greftlerin çıkarılması işlemi`,
      info2:
        'Manuel FUE ve Micromotor FUE olmak üzere iki teknik ile uygulanabilmektedir.',
      manuel: {
        title: 'Manuel Fue Tekniği',
        list: [
          'Herhangi bir motorize alet kullanılmadan,',
          `Tamamen el ve göz koordinasyonu ile özel punchlar yardımıyla
          greftlerin/saç köklerinin hissedilerek tek tek çıkarıldığı,`,
          `Greft/saç kökü kaybının olmadığı,`,
          `Konforlu bir ekim ve kısa süreli iyileşme sağlayan,`,
          `Kalıcı yara izi bırakmayan, ödem ve morluk oluşturmayan,`,
          `Doğal bir görünüme olanak (özellikle donör alanında homojen
            bir görüntü) sağlayan tekniktir.`,
        ],
        paragraph: [
          `Manuel Fue tekniği hassas bir dokunuş, fiziksel dayanıklılık,
          mükemmel bir el göz koordinasyonu ve el motor becerisi
          gerektirir.`,
          ` Manuel FUE tekniğinin temel amacı, extraction işlemi sırasında
          greftlerin ve donör bölgesinin zarar görmemesi ve donör bölgesin
          homojenliğin korunmasıdır. Homojenliğin korunuyor olması
          sebebiyle birden fazla seans sonra bile, donör alanındaki
          yoğunluk ancak yakın muayene ile görülebilmektedir.`,
          `Her bir hastanın kafa yapısı, saçların yönü ve açısı birbirinden
          farklı olabildiğinden, planlamanın buna uygun olarak yapılarak
          doğal görünüm sağlanmaktadır.`,
        ],
      },
      micromotor: {
        title: 'Micromotor Fue Tekniği',
        list: [
          `Greftlerin/saç köklerinin motorize alet kullanılarak özel
          punchlar yardımıyla tek tek çıkarıldığı,`,
          `Tek günde maksimum greft/saç kökü çıkarılmasına olanak
          sağlayan,`,
          `Kalıcı yara izi bırakmayan,`,
          `Konforlu bir ekim ve kısa süreli iyileşme sağlayan,`,
          `Uygulayan ekibin tecrübeli olması gereken tekniktir.`,
        ],
        paragraph: [
          `Micromotor Fue tekniği uygulamasında, uygulayıcının tecrübeli
          olması ve alım yapılacak alanda bölgesel olarak punch seçimi
          doğru yapılmalıdır, aksi takdirde greft kaybına ve donör alanda
          scar oluşumuna sebep olunur.`,
          `Micromotor FUE tekniğini temel amacı, bir günde maksimum sayıda
          greft/saç kökünün çıkarılmasıdır.`,
          `Her bir hastanın kafa yapısı, saçların yönü ve açısı birbirinden
          farklı olabildiğinden, planlamanın buna uygun olarak yapılarak
          doğal görünüm sağlanmaktadır.`,
        ],
      },
      shaved: {
        title: 'Uzun/Traşsız Manuel Fue',
        paragraph: [
          `Uzun/Tıraşsız manuel fue, saçların kısaltılmadan uzun haliyle
          donör alanından greflerin alınması ve ekim yapılacak alanda
          Lateral Slit yöntemiyle açılacak kanallara/yuvacıklara alınan
          greftlerin uzun olarak yerleştirilmesi işlemidir.`,
          `Uzun/Tıraşsız manuel fue operasyonu son derece tecrübe, bilgi ve
          beceri gerektiren kompleks bir işlem olmakla, doğru cerrahi
          aletlerle ameliyatın yapılması başarılı sonuçlar doğuracaktır.`,
          `Uzun/Tıraşsız manuel fue uzman ve tecrübeli ellerde oldukça
          dikkatli yapılması gereken ve çok fazla zaman alan işlem
          gerektirmesi sebebiyle tek seansta 3000 ve altı greft gereken
          açıklıklar için önerilmektedir.`,
        ],
      },
      insizyon: {
        title: 'İnsizyon (Alıcı Kesilerin Oluşturulması)',
        paragraph: [
          `İnsizyon/alıcı kesiler oluşturulması, donör bölgesinden alınan
          greftlerin/saç köklerinin ekim yapılacak bölgeye nakli için
          uygulanan tekniklerdendir.`,
          `Kesi, donör bölgesinden alınan greftlerin yerleştirilmek üzere
          ekim bölgesinde oluşturulan yuvacıklardır.`,
          `İnsizyon işlemi saç nakillerinde Lateral Slit veya Sagital Slit
          yöntemi ile yapılabilmektedir.`,
          `Ancak ekim süreci daha uzun ve zahmetli olmakla birlikte, ekim
          bölgesinde doğallığı sağlamak amacıyla merkezimizde insizyon
          işlemi “Lateral Slit” yöntemi ile yapılmaktadır.`,
        ],
      },
      leteral: {
        title: 'Lateral Slit',
        paragraph: [
          `Lateral Slit, saç büyüme yönünde yapılan insizyon/kesi işlemine
          denir. Lateral tekniği saçların doğal görünümünü sağlar. Doğal
          saç 10° 90° açılar arasında değişir. Lateral kesi ile daha küçük
          kesiler kullanarak daha fazla yoğunluk üretilir.`,
          `Doğal görünüm oluşturabilmek için saç büyüme yönü, açısı, boşluk
          ve derinlik son derece önemlidir.`,
        ],
        list: [
          `Doğal yönlendirme,`,
          `Daha az doku hasarı oluşmasını,`,
          `Yakın greft yerleştirme ve`,
          `Daha fazla hacim illüzyonu oluşmasını sağlar.`,
        ],
      },
      implantasyon: {
        title: 'İmplantasyon (Safir Fue)',
        paragraph: [
          `İmplantasyon, Manuel FUE veya Micromotor FUE tekniği ile donör
          bölgesinden çıkarılan greftlerin, Lateral Slit yöntemi ile
          açılmış olan kesilere, özel teknik aletler ile greftlere zarar
          vermeden yerleştirilmesi işlemidir.`,
        ],
      },
      sedasyon: {
        title: 'Sedasyonlu Saç Ekimi',
        paragraph: [
          `Hastanın saç ekim işlemini ağrısız ve acısız sağlamak amacıyla
          geliştirilen bir anestezi yöntemidir.`,
          `Hastaya uygulanacak sedasyon işlemi anestezi doktoru tarafından
          uygulanmaktadır.`,
          `Uygulama sonrasında hastanın bilinci açıktır ve hasta ile
          konuşmak mümkündür.`,
          `Özellikle enjeksiyon işleminden tedirgin olan panik atak vb.
          sorunları olan kişilere sedasyonlu saç ekim işlemi
          uygulanabilmektedir.`,
        ],
      },
      implantasyonDHI: {
        title: 'İmplantasyon (DHI)',
        paragraph: [
          `DHİ, donör bölgesinden alınan greftlerin/saç köklerinin ekim
          yapılacak bölgeye nakli için uygulanan tekniklerdendir.`,
          `Fue yöntemiyle alınan saç kökleri Choi Implanter adı verilen
          özel kalemlerle bölgeye transfer edilir.`,
          `DHİ tekniğinde tek bir işlemle (kesi ve saç kökünün
            yerleştirilmesi) saç kökleri yerleştirilebilmektedir.`,
          `Özel medikal kalem olan choi ımplanter ile toplanan saç
            folikülleri kalem içine teker teker çok özenli ve dikkatlice
            yerleştirilir.`,
          `Yerleştirilen saç kökleri ekim için en uygun açı belirlendikten
            ve doğru yön saptandıktan sonra ekim yapılacak olan bölgeye
            transfer edilir.`,
          `İğnenin itme özelliği ile ekim işlemi gerçekleştirilir.`,
          `Operasyonda kullanılan kalem sayısı ve kalem choi ucu sayısı
            kişinin saç kökü ve kalınlık yapısına göre değişkenlik
            göstermektedir.`,
        ],
      },
    },
    OnlineReservation: {
      title: 'Bize Ulaşın',
      subInfo: `Saç, sakal, bıyık ve kaş cerrahisi alanında uzman ekibimiz ve
      tercümanlarımız tarafından hastalara ücretsiz muayene (konsültasyon)
      ve danışma hizmeti sunuyoruz. Profesyonel danışma ve muayene
      işlemleri için lütfen aşağıdaki formu doldurunuz.`,
      formTitle: 'Konsültasyon ve İstişare Formu',
      list: [
        `* Siz müşterilerimize konsültasyon ve danışma hizmetini etkin
        bir şekilde verebilmemiz için aşağıdaki bölümlerde yer alan
        sorularla ilgili alanı doğru bir şekilde doldurun.`,
        `* Aşağıda yer alan fotoğraf ekle bölümüne örneklerde yer
        verildiği şekilde donör bölgesi (iki kulak arasında kalan alan)
        ve ekim yapılmasını istediğiniz açık alanın fotoğraflarını
        (Fotoğraflar net ve iyi kalitede olmalıdır.) ekleyiniz.`,
      ],
      labels: {
        name: 'Ad Soyad',
        birthDate: 'Doğum Tarihi',
        country: 'Ülke / Şehir',
        phone: 'Telefon',
        email: 'E-Mail',
        job: 'Meslek',
        reference: 'Referans/Bize Nasıl Ulaştınız?',
        patientsAdvice: 'Hastanızın Tavsiyesi',
        socialMedia: 'Sosyal Medya',
        forum: 'Forum',
        magazine: 'Dergi/Gazete',
        internet: 'İnternet',
        other: 'Diğer',
        transplantTitle: 'Ne ekimi istiyorsunuz?',
        hair: 'Saç Ekimi',
        beard: 'Sakal Ekimi',
        mustache: 'Bıyık Ekimi',
        eyebrow: 'Kaş Ekimi',
        informTransplantTitle: `Saç ekimi ile kapatılmasını istediğiniz bölgelerinizi bildiriniz.
        Ekim sonucu beklentileriniz nelerdir?`,
        fueTitle: 'FUE Yöntemi tercihiniz nedir?',
        manuelFue: 'Manuel FUE',
        micromotorFue: 'Micromotor FUE',
        micromotorShaved: 'Manuel FUE Traşlı',
        micromotorUnShaved: 'Manuel FUE Traşsız',
        shavedInfo: `Traşsız saç ekimi açıklığın az olduğu 3000 ve altı greft ile
        açıklığın kapatılabileceği durumlarda tercih edilmesi
        önerilmektedir.`,
        transplantTypeTitle: `Ekim tercihiniz nedir?`,
        leteral: 'Lateral Slit Saç Ekimi',
        dhi: 'DHİ Saç Ekimi',
        sedasyon: 'Sedasyonlu Saç Ekimi',
        hairLossMedicine: `Saç dökülmesini önlemeye yönelik kullandığınız herhangi bir ilaç
        vs. var mıdır? Var ise kullandığınız ilacı bildiriniz.`,
        transplantBefore: `Daha öncesi ekim yaptırdınız mı? Cevabınız evet ise, ekimi
        yaptırdığınız klinik, doktor, kullanılan teknik ve ekilen greft
        sayısı ile ekim sonucu ile ilgili düşüncelerinizi bildiriniz.`,
        hadAnyIllness: `Geçirdiğiniz herhangi bir hastalığınız var mı?`,
        none: 'Yok',
        verem: 'Verem',
        tansiyon: 'Tansiyon',
        heart: 'Kalp Hastalıkları',
        seker: 'Şeker Hastalıkları',
        eklem: 'Eklem Romatizması',
        epilepsi: 'Epilepsi (Sara)',
        romatizma: 'Ateşli Romatizma',
        zuhrevi: 'Zührevi Hastalık',
        guatr: 'Guatr (Troid)',
        blood: 'Kan Hastalıkları (Hepatit B - C)',
        bobrek: 'Böbrek, Karaciğer Bozuklukları',
        astim: 'Astım',
        saman: 'Saman Nezlesi',
        sarilik: 'Sarılık',
        akciger: 'Akciğer Hastalıkları',
        aids: 'AIDS',
        treatmentTitle:
          'Şu anda bir tedavi görüyor musunuz? İlaç kullanıyor musunuz?',
        alergicTo: 'Alerji duyduğunuz ilaç var mıdır? Belirtiniz.',
        antibiyotik: 'Antibiyotik Alerjisi',
        painkiller: 'Ağrı Kesici Alerjisi',
        anestezik: 'Anestezik İlaçlar Alerjisi',
        localTitle: 'Daha önce local anestezi ile operasyon geçirdiniz mi?',
        yes: 'Evet',
        no: 'Hayır',
        yesBut: 'Evet ise Operasyon Bilgisi',
        skinProblem: 'Herhangi bir cilt sorununuz var mı?',
        dermatit: 'Dermatit',
        dermatitQuote: '(Egzama)',
        desquamasyon: 'Desquamasyon',
        desquamasyonQuote: '(Kepeklenme, kavlama, soyulma)',
        folikulit: 'Folikülit',
        folikulitQuote: '(Kıl kökü iltihabı)',
        hipertrofik: 'Hipertrofik',
        keloid: 'Keloid',
        photoAddTitle: 'Fotoğraf Ekleme Bölümü',
        send: 'Gönder',
      },
    },
    OperasyonSonrasi: {
      title1: 'OPERASYON',
      title2: 'SONRASI',
      title3: 'DİKKAT EDİLMESİ',
      title4: 'GEREKEN HUSUSLAR',
      list1: [
        `Doktorunuzun verdiği ilaçları doktorunuzun önerileri
        doğrultusunda düzenli olarak kullanınız. (Antibiyotik ve Ağrı
        Kesici vs.)`,
        `Operasyonu takip eden 5 (Beş) gün süresince boyun yastığı
        kullanılarak sırt üstü uyumanız gerekir. (Sağa, sola dönmeniz
        veya yüz üstü yatmanız ekim alanına zarar verebilir.)`,
        `Operasyonu takip eden 5 (Beş) gün süresince cinsel temastan uzak
        durunuz.`,
        `Bir) hafta alkol ve sigara kullanmayınız.`,
        `10 (On) gün ekim alanını yağmur damlalarından ve güneşten
        koruyunuz.`,
        `10 (On) gün şapka ve bandana takmak yasaktır.`,
      ],
      list2: [
        `10 (On) gün süresince fermuarlı veya düğmeli kıyafetler tercih
        edilmeli, baştan geçecek kıyafetler giyinilmemelidir.`,
        `1 (Bir) ay süresince spor, yüzme, solaryum, sauna, hamam, jakuzi
        ve güneşlenme yasaktır.`,
        `Operasyon sonrası çok dikkatli olunmalı, özellikle ilk 10 (On)
        gün olmak üzere 6 (altı) ay süresince ekim alanının darbe
        almamasına özen gösterilmelidir.`,
        `6 (Altı) ay süresince saçınızı boyatmayınız, jöle ve sprey
        kullanmayınız ve saçınızı makasla kestiriniz. 6 (Altı) ay
        süresince kesinlikle makine kullanılmamalıdır.`,
        `2 (iki) yıl süresince propecia kullanmanız önerilmektedir.
        Etkisini korumak için sürekli kullanım önerilir. Propecia, hem
        mevcut saçlarınızı güçlendirerek dökülmesini önler hem de mevcut
        ve ekilen saçlarınızın kalınlaşmasını sağlar.`,
      ],
      title5: 'Operasyon Sonrası Hasta Bilgilendrime ve Yıkama İşlemi',
      list3: [
        `Çok nadirde olsa yüzünüzde ödem/şişlik olur ise endişelenmeyin.
        3 (Üç) gün içerisinde ödem/şişlik inecektir. Ödem/şişlik
        oluşmaması için kliniğimizce tarafınıza verilen buz jelini
        düzenli bir şekilde uygulayınız ve başınız öne doğru eğmemeye
        özen gösteriniz.`,
        `Ekim bölgesinde kaşıntı ve/veya sivilcelenmeler oluşur ise
        kliniğimize başvurunuz.`,
      ],
      list4: [
        `Operasyondan sonra 10 (On) gün süresince her gün düzenli olarak
        yıkamalarınızı yapınız ve 10. (Onuncu) gün ekim bölgesinde
        oluşan kabukları kliniğimizce size anlatıldığı gibi
        temizleyiniz.`,
      ],
      title6: 'Yıkamalar aşağıda belirtildiği şekilde yapılmalıdır.',
      list5: [
        `Öncelikle ekim bölgesine merkezimiz tarafından önerilen
        losyonunuzu sürün ve 15 dakika bekleyin.`,
        `Basınçsız ve ılık su ile 15 dakika önce sürmüş olduğunuz losyonu
        hafif dokunuşlarla temizleyin. Ekim bölgesine sert ve ileri geri
        hareketler yapmayın.`,
        `Merkezimizce önerilen şampuanı iki eliniz arasında ovuşturarak
        köpürmesini sağladıktan sonra hafif dokunuşlarla ekim ve donör
        bölgesine uygulayın ve basınçsız ve ılık su ile köpük kalmayacak
        şekilde durulayın. Şampuanlama ve durulama işlemini iki kez
        tekrarlayınız.`,
      ],
      list6: [
        `Ekim alanı, kağıt havlu ile yumuşak-hafif dokunuşla
        kurulanmalıdır.`,
        `Size verilen şampuanı özellikle ilk 10 (On) gün mutlaka
        kullanınız. 10 (On) gün sonrasında merkezimizce önerilen şampuan
        bittikten sonra dilediğiniz şampuanı kullanabilirsiniz.`,
      ],
    },
  },
  EN: {
    Global: {
      Header: {
        homepage: 'Homepage',
        about1: 'About',
        about2: 'S&S MED',
        aboutTransplant1: 'About Hair',
        aboutTransplant2: 'Transplant',
        clinic: 'Clinic',
        onlineReservation1: 'Online',
        onlineReservation2: 'Reservation',
        contact: 'Contact',
        institutional: 'Institutional',
        hairLoss: 'Hair Loss Causes',
        medicalTreatment: 'Medical Treatment',
        fue: 'FUE Hair Transplant',
        eyebrow: 'Eyebrow Transplantation',
        beard: 'Beard/Mustache Transplantation',
        postOperation: 'Post-Operation Instructions',
        fueInner: {
          manuel: 'Manual FUE Hair Transplant',
          micromotor: 'Micromotor FUE Hair Transplant',
          unshaved: 'Long/Unshaven Manual FUE',
          leteral: 'Lateral Slit Hair Transplant',
          dhi: 'DHI Hair Transplant',
          sedasyon: 'Sedated Hair Transplant',
        },
      },
    },
    Home: {
      HeadInfo: {
        title: 'ARE YOU READY FOR NEW LOOK AND TRANSFORMATION?',
        paragraph1: `FUE technique (Manual FUE and/or Micromotor FUE) applied by our expert staff in our
        center, hair, beard, mustache and eyebrow transplantation is performed with shaved and/or
        unshaven according to the characteristics of the person who will have the transplantation.
        Hair, beard, mustache and eyebrow transplantation is concluded in three steps: extraction
        (graft/hair follicle-follicle removal process), incision (incision/channel opening process in the
        area where the grafts will be placed) and implantation (placing process).`,
        paragraph2: `We deciding together with our patients which FUE technique will be applied in Hair, Beard,
        Mustache and Eyebrow transplant operation.`,
      },
      Fue: {
        title: 'FUE METHOD',
        subTitle1: 'MANUAL FUE TECHNIQUE',
        subTitle2: 'MICROMOTOR FUE TECHNIQUE',
        paragraph1: `Hair, Beard, Mustache and Eyebrow Transplantation is a complex procedure that includes
        important elements. The positive results of the placing process; It depends on patient&#39;s
        characteristics, design, graft removal, graft preservation, incision, transplantation, post-
        operative care and careful application of complementary therapies.`,
        paragraph2: `Each patient is carefully analyzed by our experienced staff using advanced technical
        equipment then hair transplant process and technique determined.`,
        paragraph3: `Preoperative blood analysis and electrocardiogram are performed for each patient..`,
        paragraph4: `Necessary anesthetic procedures during the operation are determined and applied by our
        anesthesiologist who works in our center.`,
        paragraph5: `In our center, high quality surgical materials are used and the sterilization of surgical
        materials are carried out regularly.`,
        paragraph6: `The parameters affecting the graft are carefully controlled with the latest technology. Grafts
        are kept alive for a long time by keeping them in hypothermosol and ATP fluids. Thus, graft
        loss does not occur.`,
        paragraph7: `Our translator, who works in our center, accompanies the patient during the operation.`,
      },
    },
    About: {
      title: 'ABOUT S&S MED',
      paragraph1: `S&S MED is a healthcare institution that provides patient satisfaction-oriented services in
        Atakent Acıbadem Hospital, where it has a contract with its experienced teams that have
        combined their experience in the field of health and hair transplantation.`,
      paragraph2: `The basic principle of our center is to follow the developments in the international arena in
        the field of hair transplantation and hair loss treatments by our expert team, and to determine
        and evaluate according to the characteristics of the person who will have the transplantation
        within the framework of ethical rules, and to apply the most appropriate solution for the
        patient.`,
      paragraph3: `Our main goal is to create a full and natural appearance with optimum hair transplantation.`,
      paragraph4: `Our expert staff can apply different methods and techniques according to the characteristics
        and preferences of each patient.`,
      paragraph5: `Our experts attend both national and international congresses (ISHRS 2 nd Mediterranean FUE
            Workshop Madrid/Spain 24-26 May 2013, ISHRS European Hair Transplant Seminar
            Brussels/Belgium 13-15 June 2014, 3 rd Mediterranean FUE Workshop Istanbul/Turkey 25-27
            June 2015) participated and took an active role.Continuing to serve with 13 years of experience in the field of hair transplant surgery, our
            experts have participated in thousands of hair transplant as well as eyebrow, mustache and
            beard transplant operations accompanied by plastic surgeons.`,
    },
    SacDokulmesiNedenleri: {
      title1: 'HAIR LOSS',
      title2: 'CAUSES',
      subTitle1Part1: `Male Pattern Hair Loss`,
      subTitle1Part2: `(Androgenic Alopecia)`,
      subTitle2: `Hair Loss in Women`,
      paragraph1: `The most important cause of hair loss is genetic factors. Hair loss and scatter hair in the
        family is a serious problem that affects seven out of every ten men starting from the age of 18,
        is less common in women, and affectıng the person physically, mentally and socially.`,
      paragraph2: `The most common type of hair loss is androgenic hair loss. In the early stages of shedding,
        the hair becomes thinner (miniaturization) and loses its pigment and becomes lighter in color
        and has a weak, unviable appearance. Male-pattern hair loss, the age of occurrence which
        regresses to the age of 18, begins with the regression of the forehead hairline and manifests
        itself as hair thinning/scattering in all men.`,
      paragraph3: `Loss of hair follicles can be prevented and inactive stem cells are stimulated with some drugs
        recommended to be used in this period and laser biostimulation. After the androgenic type of
        hair loss reaches a certain level, the only solution is hair transplantation. The hair on the upper
        part of the neck and the area between the two ears does not fall out as they are insensitive to
        testosterone degradation products. These hairs are used in hair transplantation.`,
      paragraph4: `Hair loss is a serious problem that is increasingly seen in women today and affects social life.
        Today, hair loss problem is seen in one out of every 10 women. Hair loss is generally seen in
        women as a thinning and scattering that affect the entire scalp.`,
      paragraph5: `While the main cause of hair loss in men is genetic, the causes of hair loss in women are;
        Hormonal changes, some diseases such as thyroid problems, anemia, mineral deficiencies,
        especially iron, stress, traumas may occur.`,
      paragraph6: `In women, the causes of hair loss are investigated in order to rule out some systemic and
        hormonal factors. It is not as easy to diagnose and treat the causes of hair loss in women as it
        is in men.`,
    },
    SacDokulmesiTedavileri: {
      title1: `HAIR LOSS`,
      title2: `TREATMENTS`,
      subInfo: `In our clinic, we have different practices on hair loss and its treatments.`,
      subTitle1: `PRP HAIR TREATMENT`,
      paragraph1: `P.R.P. In other words, "Platelet-Rich Plasma" is the application of platelets obtained from your own blood, through special processes, to the transplanted area during and after hair transplantation, or to areas that become sparse during the shedding phases.`,
      paragraph2: `It has been observed that the adherence/success rate of the follicles in the transplanted area increased and the wound healing accelerated after the operation in hair transplant patients who underwent PRP treatment. It can be applied for treatment purposes in men or women who have thinned but not completely shed hair due to genetic or seasonal reasons.`,
      paragraph3: `In addition, successful results are obtained in the regional hair loss disease, which is called 'Androgenic Areata'. The PRP system can be applied to every hair transplant case. Since this procedure, performed with your own blood, does not contain other substances, there is absolutely no risk of allergic reaction or infection after the treatment.`,
      paragraph4: `P.R.P is a blood product that has been used to accelerate wound healing all over the world since the 1970s. This effect of P.R.P has been approved by the FDA (Food and Drug Administration) in the USA.`,
      subTitle2: `HAIR MESOTHERAPY`,
      paragraph5: `Hair mesotherapy is a treatment method applied to the scalp in order to stop hair loss and to activate some of the dormant hair follicles under the skin and revitalize them.`,
      paragraph6: `Hair mesotherapy is the injection of selected drug mixtures into the deep dermis layer of the scalp with small doses by special needles and a special technique, after determining the cause of hair shedding.This special amino acid, vitamin and mineral mixture formula provides nourishment to activated stem cells. Thus, instead of short-term changes, with long-term permanent structures, weakened and lifeless hair can be transformed back into a healthy state, blood circulation in the scalp can be activated, lymphatic drainage can be regulated, and collagen structure can be strengthened.`,
      paragraph7: `For the treatment of hair loss, mesotherapy sessions, which are applied to feed the accelerated metabolism of all activated stem cells, last for 15 minutes and are applied by our experts.`,
      subTitle3: `FINASTERIDE – MEDICAL TREATMENT`,
      paragraph8: `Finasteride is a 5α-reductase type 2 inhibitor. This reduces the conversion of testosterone to dehydrotestosterone (DHT).`,
      paragraph9: `Significant increases in hair count and thickness are observed in 80% of people using finasteride. It not only thickens thinning hair, but also stops hair loss. It does not bring back the lost hair. It does not cause hair growth on the body.`,
      paragraph10: `It starts to show its effect in about 4-6 months after it is used. It should be used for 2 years to achieve maximum effect. Continuous use is recommended to maintain its effect.`,
      paragraph11: `Finasteride is effective in all areas of thinning hair, but it is more effective on the middle and top areas.`,
      paragraph12: `The recommended dose for finasteride is 1mg per day. There are no known drug interactions.`,
      paragraph13: `Finasteride should not be used by pregnant women and by patients with liver disease.`,
      paragraph14: `The reported side effects of finasteride are: decrease or increase at the indicated rates in libido (1.9%), erectile dysfunction (1.3%), and ejaculation dysfunction (1%). In some patients, drug side effects persist for about 3 months after discontinuing use. Finasteride works to reduce prostate-specific antigen (PSA) levels and may rarely cause gynecomastia. Finasteride is the first drug approved by the FDA for hair. Primarily, it is used to balance hair loss, protect existing hair and reduce hair loss.`,
      subTitle4: `Minoxidil – Medical Treatment`,
      paragraph15: `Although it is applied today, it is not recommended by our clinic.`,
    },
    KasEkimi: {
      title: 'EYEBROW TRANSPLANT',
      paragraph1: `Eyebrow transplantation's application methods are the same as hair transplantation.`,
      paragraph2: `Hair follicles taken with the FUE method are transferred to the eyebrow area, which is sparse
      or absent, with the help of special forceps by considering naturalness.`,
      paragraph3: `Eyebrow transplantation takes an average of 4/5 hours. After the operation, the patient can
      continues his normal daily life.`,
    },
    SakalEkimi: {
      title1: `BEARD/ MUSTACHE`,
      title2: `TRANSPLANT`,
      paragraph1: `Beard and/or Mustache transplantation&#39;s application methods are the same as hair
        transplantation.`,
      paragraph2: `Hair follicles taken with the FUE method are transferred to the beard and mustache area with
        the help of special forceps.`,
      paragraph3: `The face area has a sensitive structure, however the transplant should be done by experienced
        people.`,
    },
    FooterForm: {
      title: `Free Consultation`,
      name: `Name`,
      email: 'E-Mail',
      phone: 'Phone',
      nationality: 'Nationality',
      subject: 'Subject',
      message: 'Message',
      send: 'Send',
    },
    Fue: {
      title1: 'FUE',
      title2: '(Follicular',
      title3: 'Unit Extraction)',
      top: {
        paragraph1: `FUE is the method used in the removal of grafts in hair transplantation and is the latest
          development in the field of hair transplant surgery.`,
        paragraph2: `In the FUE technique, the grafts are taken from the donor area (the area between the two ears
            where the hair will be taken) using micro punches.`,
        paragraph3: `The FUE technique is the extraction of a large number of grafts one by one, with the optimum
          number and quality of grafts, without leaving any scars in the donor area. In the FUE
          technique, no incision is made on the scalp.`,
        paragraph4: `The FUE method allows more grafts to be taken/removed compared to other techniques. The
         FUE method increases the number of grafts to be transplanted compared to other methods and
         allows the patient to have more dense/gross hair.`,
      },
      benefitTitle: 'Benefits of the FUE Method',
      benefits: {
        left: [
          `It does not require any stitches and does not leave permanent scars.`,
          `Provides a comfortable and short-term healing process.`,
          `In general, edema and bruising do not occur.`,
          `Post-operative pain is almost absent.`,
        ],
        right: [
          `After the operation, the activity is less limited and the daily life is returned earlier.`,
          `Maximum number of grafts can be extracted.`,
          `The patient can use his hair as long or short as he wishes.`,
        ],
      },
      info1: `Extract of hair follicles/grafts with the FUE method can be applied with`,
      info2: 'two techniques, Manual FUE and Micromotor FUE',
      manuel: {
        title: 'Manuel Fue Technique',
        list: [
          'No use of any motorized tool,',
          `The grafts/hair follicles are removed one by
          one by help of special punches with complete
          hand and eye coordination,`,
          `There is no loss of graft/hair root,`,
          `Providing a comfortable transplant and short-
          term recovery,`,
          `Does not leave permanent scars, does not cause
          edema and bruises,`,
          `It is a technique that allows a natural
          appearance (especially a homogeneous image
          in the donor area).`,
        ],
        paragraph: [
          `Manual Fue technique requires delicate touch,
          physical endurance, excellent hand-eye
          coordination and hand motor skills.`,
          `The main purpose of the manual FUE technique is not to damage the grafts and the donor area during
          the extraction process and to preserve the
          homogeneity of the donor area. Since the
          homogeneity is preserved, even after more than one
          session, the density in the donor area can only be
          seen with close examination.`,
          `Since each patient&#39;s head structure, direction and
          angle of the hair may be different from each other,
          a natural appearance is provided by making the
          plan accordıng to that.`,
        ],
      },
      micromotor: {
        title: 'Micromotor Fue Technique',
        list: [
          `Grafts/hair follicles are extracted one by one
          with the help of special punches using a
          motorized tool,`,
          `Allows maximum graft/hair root removal in a
          single day,`,
          `Does not leave permanent scars,`,
          `It is a technique that the applying team should
          be experienced.`,
        ],
        paragraph: [
          `In Micromotor FUE technique, the practitioner
          must be experienced and the regional punch
          selection should be made correctly in the area to
          be taken, otherwise graft loss and scar formation
          in the donor area will be formed.`,
          `The main purpose of the Micromotor FUE
          technique is to remove the maximum number of
          grafts/hair follicles in one day.`,
          `Since each patient&#39;s head structure, direction and
          angle of the hair may be different from each other,
          a natural appearance is provided by making the
          plan accordıng to that.`,
        ],
      },
      shaved: {
        title: 'LONG/UNSHAVED MANUAL FUE',
        paragraph: [
          `Long/Unshaved manual FUE is the process of removing the grafts from the donor area in
          their long form without shortening the hair and placing the grafts taken in the channels to be
          opened with the Lateral Slit method in the area to be transplanted.`,
          `Long/Unshaved manual FUE operation is a complex procedure that requires a great deal of
          experience, knowledge and skill, and performing the operation with the right surgical
          instruments will yield successful results.`,
          `Long/Unshaved manual FUE is recommended for gaps requiring 3000 or less grafts in a
          single session, as it requires a very time-consuming procedure, which must be done very
          carefully by expert and experienced hands.`,
        ],
      },
      insizyon: {
        title: 'INCISION (CREATION OF RECIPIENT SMALL CUTS)',
        paragraph: [
          `Creation of incision/recipient incisions is one of the techniques applied for the transplantation
          of grafts/hair follicles taken from the donor area to the area to be transplanted.`,
          `The incision is the cut created in the transplanted area to place the grafts taken from the donor
          area.`,
          `Incision process can be done with Lateral Slit or Sagittal Slit method in hair transplants.`,
          `However, although the planting process is longer and more laborious, incision is made with
          the &quot;Lateral Slit&quot; method in our center in order to ensure naturalness in the transplant area.`,
        ],
      },
      leteral: {
        title: 'Lateral Slit',
        paragraph: [
          `Lateral Slit is an incision made in the direction of hair growth. The lateral technique provides
          the natural appearance of the hair. Natural hair ranges from 10° to 90° angles. With the lateral
          incision, more density is produced by using smaller incisions.`,
          `Hair growth direction, angle, space and depth are extremely important in order to create a
          natural look.`,
        ],
        list: [
          `Natural orientation,`,
          `Less tissue damage occurs,`,
          `Close graft placement and`,
          `Provides the illusion of more volume.`,
        ],
      },
      implantasyon: {
        title: 'IMPLANTATION (PLANTING PROCESS)',
        paragraph: [
          `Implantation is the process of placing the grafts extracted from the donor area with the
          Manual FUE or Micromotor FUE technique into the incisions made with the Lateral Slit
          method, without damaging the grafts with special technical tools.`,
        ],
      },
      sedasyon: {
        title: 'SEDATION HAIR TRANSPLANT',
        paragraph: [
          `It is an anesthesia method developed to ensure the patient's hair transplant procedure is
          painless and tendernessless.`,
          `The sedation procedure applied to the patient by the anesthesiologist.`,
          `After the application of anesthesıa, the patient is conscious and it is possible to talk with the
          patient.`,
          `Especially who have panic attacks or who are nervous from the injection process. If Patient
          has problem sedated hair transplantation can be applied.`,
        ],
      },
      implantasyonDHI: {
        title: 'DHI HAIR TRANSPLANT',
        paragraph: [
          `DHI is one of the techniques applied for the transplantation of grafts/hair follicles taken from
          the donor area to the area to be transplanted.`,

          `Hair follicles taken with the FUE method are transferred to the area with special pens called
          Choi Implanter.`,

          `In the DHI technique, hair follicles can be placed in a single operation (incision and hair root
            placement). The hair follicles collected with the Choi Implanter, which is a special medical
            pen, are carefully placed into the pen one by one.`,

          `After determining the most suitable angle for planting and determining the right direction, the
          placed hair follicles are transferred to the area to be planted.`,

          `Placing is performed with the pushing feature of the needle.`,
          `The number of pencils used in the operation and the number of pencil choi tips vary
          according to the hair root and thickness structure of the person.`,
        ],
      },
    },
    OnlineReservation: {
      title: 'Contact Us',
      subInfo: `We offer free examination and consultation services to patients by our expert team and translators in the field of hair, beard, mustache and eyebrow transplantation. Please fill out the form below for professional consultation.`,
      formTitle: 'Consultation Form',
      list: [
        `* In order, to provide our customers with an effective consultation service, fill in the questions in the sections below correctly.`,
        ` * In the add photo section below, add the photos of the donor area (the area between the two ears) and the open area you want to plant (Photos must be clear and of good quality) as given in the examples.`,
      ],
      labels: {
        name: 'Name and Surname',
        birthDate: 'Birthdate',
        country: 'Country',
        phone: 'Phone',
        email: 'E-Mail',
        job: 'Job',
        reference: 'Reference / How did you reach us?',
        patientsAdvice: `Patient's Advice`,
        socialMedia: 'Social Media',
        forum: 'Forum',
        magazine: 'Magazine/Newspaper',
        internet: 'Internet',
        other: 'Other',
        transplantTitle: 'What do you want to transplant ?',
        hair: 'Hair',
        beard: 'Beard',
        mustache: 'Mustache',
        eyebrow: 'Eyebrow',
        informTransplantTitle: `Inform the areas that you want to be covered with hair transplantation. What are your expectations for the result of transplantation?`,
        fueTitle: 'What is your FUE Method preference?',
        manuelFue: 'Manuel FUE',
        micromotorFue: 'Micromotor FUE',
        micromotorShaved: 'Shaved Hair Transplant ',
        micromotorUnShaved: 'Unshaved Hair Transplant',
        shavedInfo: `Unshaven hair transplantation is recommended to be preferred in cases where the gap is small and the gap can be closed with 3000 or less grafts.`,
        transplantTypeTitle: `What is your implantation preference?`,
        leteral: 'Lateral Slit Transplantation',
        dhi: 'DHİ Hair Transplantation',
        sedasyon: 'Sedated Hair Transplantation',
        hairLossMedicine: `Are there any medications you use to prevent hair loss? If yes, report the drug you use..`,
        transplantBefore: `Have you make transplant before? If your answer is yes, please let us know your thoughts about the clinic, doctor, technique used, number of grafts transplanted and the result of the transplant.`,
        hadAnyIllness: `Have you ever had any illness?`,
        none: 'No',
        verem: 'TB',
        tansiyon: 'Blood Pressure',
        heart: 'Heart Diseases',
        seker: 'Diabetus',
        eklem: 'Joint Rheumatism',
        epilepsi: 'Epilepsy',
        romatizma: 'Rheumatic Fever',
        zuhrevi: 'Venereal Disease',
        guatr: 'Guator Thyroid Diseases',
        blood: 'Blood Diseases (Hepatitis B - C)',
        bobrek: 'Kidney, Liver Disorders',
        astim: 'Asthma',
        saman: 'Hay fever',
        sarilik: 'Jaundice',
        akciger: 'Lung diseases',
        aids: 'AIDS',
        treatmentTitle:
          'Are you currently receiving any treatment? Are you using medication?',
        alergicTo:
          'Are there any medications you are allergic to? Please specify.',
        antibiyotik: 'Antibiotic Allergy',
        painkiller: 'Painkiller Allergy',
        anestezik: 'Anesthetic Drugs Allergy',
        localTitle: 'Have you had an operation with local anesthesia before?',
        yes: 'Yes',
        no: 'No',
        yesBut: 'If yes, Operation Information',
        skinProblem: 'Do you have any skin problems?',
        dermatit: 'Dermatitis',
        dermatitQuote: '',
        desquamasyon: 'Desquamation',
        desquamasyonQuote: '(dandruff, flaking, peeling)',
        folikulit: 'Folliculitis',
        folikulitQuote: '(Inflammation of the hair follicle)',
        hipertrofik: 'Hypertrophic',
        keloid: 'Keloid',
        photoAddTitle: 'Photo Adding Section',
        send: 'Send',
      },
    },
    OperasyonSonrasi: {
      title1: 'POST',
      title2: 'OPERATIVE',
      title3: 'INSTRUCTIONS',
      title4: '',
      list1: [
        `Use the medicines given by your doctor regularly with your doctor's recommendations. (Antibiotics and Painkillers etc.).`,
        `You must sleep on your back using a neck pillow for 5 (five) days following the operation. (Turning to the right, left or lying face down may damage the implantation area.)`,
        `Avoid sexual contact for 5 (five) days following the operation.`,
        `Do not use alcohol or cigarettes for (one) week.`,
        `Protect the planting area from raindrops and sun for 10 (ten) days.`,
        `It is forbidden to wear a hat (the hat provided by our clinic can be used) and a bandana for 10 (ten) days.`,
      ],
      list2: [
        `For 10 (ten) days, clothes with zippers or buttons should be preferred, and clothes pass through the head should not be worn.`,
        `Sports, swimming, solarium, sauna, Turkish bath, jacuzzi and sunbathing are prohibited for 1 (one) month.`,
        `Care should be taken after the operation, especially during the first 10 (ten) days and for 6 (six) months, care should be taken not to damage the planting area.`,
        `Do not color your hair for 6 (six) months, do not use gel or spray, and cut your hair with scissors. The hair machine should not be used for 6 (six) months.`,
        `It is recommended to use Propecia for 2 (two) years. Continuous use is recommended to maintain its effect. Propecia not only strengthens your existing hair and prevents it from falling out, but also thickens your existing and transplanted hair.`,
      ],
      title5: 'POST OPERATIVE PATIENT INFORMATION AND WASHING PROCESS',
      list3: [
        `Do not worry if you rarely have edema/swelling on your face. The edema/swelling will subside within 3 (three) days. To prevent edema/swelling, apply the ice gel given to you by our clinic regularly and be careful not to tilt your head forward.`,
        `If itching and/or acne occur in the planting area, contact our clinic.`,
      ],
      list4: [
        `Should you do washing regularly every day for 10 (ten) days after the operation then on the 10th (tenth) day, clean the crusts formed in the planting area as explained to you by our clinic.`,
      ],
      title6: 'Washing should be done as indicated below',
      list5: [
        `First of all, apply your lotion recommended by our center to the planting area and wait for 15 minutes.`,
        `Clean the lotion you applied 15 minutes ago with light touches with warm water without pressure. Do not make hard and back and forward movements to the planting area.`,
        `After rubbing the shampoo (recommended by our center) to foam between your hands, apply it to the transplant and donor area with light touches, and rinse the foam with warm water without pressure. Repeat the shampooing and rinsing process twice.`,
      ],
      list6: [
        `Make sure to use the shampoo given to you, especially for the first 10 (ten) days. After 10 (ten) days, the shampoo recommended by our center is finished, you can use any shampoo you want.`,
      ],
    },
  },
  ESP: {
    Global: {
      Header: {
        homepage: 'PÁGINA DE INICIO',
        about1: 'SOBRE',
        about2: 'S&S MED',
        aboutTransplant1: 'EL TRASPLANTE',
        aboutTransplant2: 'CAPILAR',
        clinic: 'LA CLÍNICA',
        onlineReservation1: 'RESERVACIÓN',
        onlineReservation2: 'ONLINE',
        contact: 'CONTACTO',
        institutional: 'CORPORATIVO',
        hairLoss: 'Las causas de la caída del pelo',
        medicalTreatment: 'El tratamiento medical',
        fue: 'Fue Trasplante Capilar ',
        eyebrow: 'Trasplante de Cejas',
        beard: 'Trasplante de Barba/Bigote',
        postOperation: 'Las instrucciones postoperatorias',
        fueInner: {
          manuel: 'Trasplante Capilar Fue Manual',
          micromotor: 'Trasplante Capilar Fue con Micromotor',
          unshaved: 'Fue Manual Sin Afeitar',
          leteral: 'Trasplante Capilar / Corte Lateral',
          dhi: 'Trasplante capilar con el método DHI',
          sedasyon: 'Trasplante Capilar con Sedación',
        },
      },
    },
    Home: {
      HeadInfo: {
        title: '¿ESTÁS LISTO PARA LA INNOVACIÓN Y EL CAMBIO? ',
        paragraph1: ` Con la técnica FUE (FUE Manual y/o FUE con Micromotor) realizada por nuestro personal experto en nuestro centro, se hace el trasplante capilar, barba, bigote y cejas con el pelo afeitado o sin afeitar según las características de la persona que se va a tener el implante. El trasplante capilar, barba, bigote y cejas se basa en tres pasos: extracción (extracción del folículo piloso), incisión (proceso de incisión/apertura de canal en el área donde se colocarán los injertos) e implantación (proceso de implantación).
        `,
        paragraph2: `Decidimos junto con nuestros pacientes qué método de FUE se utiliza en la operación de trasplante capilar, barba, bigote y cejas.`,
      },
      Fue: {
        title: 'EL MÉTODO FUE',
        subTitle1: 'FUE MANUAL',
        subTitle2: 'FUE CON MICROMOTOR',
        paragraph1: `El trasplante capilar, trasplante de barba, trasplante de bigote y trasplante de cejas son  unos procedimientos complejos que incluyen elementos importantes. Tener resultados positivos después del trasplante depende de las características del paciente, el diseño, la extracción de los folículos, la preservación del injerto, la incisión, el trasplante, el cuidado postoperatorio y la aplicación de las terapias complementarias.
        `,
        paragraph2: `Cada paciente es analizado con cuidado por nuestro personal experimentado utilizando equipos técnicos avanzados y se determina el proceso de trasplante capilar.
        `,
        paragraph3: `Se hacen análisis de sangre y electrocardiograma preoperatorios para cada paciente.
        `,
        paragraph4: `Los procedimientos anestésicos durante la operación son determinados y aplicados por nuestro anestesiólogo que trabaja en nuestro centro.
        `,
        paragraph5: `En nuestro centro se utiliza material quirúrgico de alta calidad y la esterilización del material quirúrgico se realiza periódicamente.
        `,
        paragraph6: `Los parámetros que afectan la calidad del injerto se controlan con cuidado con la última tecnología. Los folículos se mantienen vivos durante mucho tiempo en hipotermosol y fluidos ATP. Por lo tanto, no se produce la pérdida del injerto.
        `,
        paragraph7: `Nuestro traductor, que trabaja en nuestro centro, acompaña al paciente durante la operación.`,
      },
    },
    About: {
      title: 'SOBRE S&S MED',
      paragraph1: `S&S MED es una institución sanitaria que ofrece servicios orientados a la satisfacción del paciente en está funcionando el Hospital Atakent Acıbadem, con experimentados equipos especialistas que han combinado su experiencia en salud y trasplante capilar.`,
      paragraph2: `El primer principio de nuestro centro es seguir los desarrollos internacionales de trasplante capilar y de los tratamientos de pérdida de cabello, para poder determinar y evaluar las características de las personas con estándares éticos, y ofrecer la solución más adecuada para ellos. `,
      paragraph3: `Nuestro objetivo principal es ayudar a los pacientes a tener los resultados más naturales y satisfactorios después del trasplante capilar.`,
      paragraph4: `Nuestro personal experto puede aplicar diferentes métodos y técnicas según las características y preferencias de cada paciente.`,
      paragraph5: `Nuestros expertos atendieron a congresos nacionales e internacionales (ISHRS 2nd Mediterranean Fue Workshop Madrid/España 24-26 de mayo de 2013, ISHRS European Hair Transplant Seminar Bruselas/Bélgica 13-15 de junio de 2014, 3. Mediterranean FUE Workshop Estambul/Turquía 25-27 de junio de 2015 ) y tomaron una parte activa en estas ocaciones importantes. Estamos sirviendo con 13 años de experiencia de la cirugía de trasplante capilar, nuestros expertos han participado en miles de operaciones de trasplante capilar acompañados por cirujanos plásticos, así como también en las operaciones de trasplante de cejas, bigote y barba.`,
    },
    SacDokulmesiNedenleri: {
      title1: 'CAÍDA DEL',
      title2: 'CABELLO Y CAUSAS',
      subTitle1Part1: `Pérdida de cabello de patrón masculino `,
      subTitle1Part2: `(alopecia androgénica)`,
      subTitle2: `Pérdida de cabello en mujeres`,
      paragraph1: `La causa más importante de la caída del cabello son los factores genéticos. La caída y adelgazamiento del pelo en la familia es un problema grave que afecta a siete de cada diez hombres a partir de los 18 años, es menos común en las mujeres, que afecta a la persona física, psíquica y socialmente.`,
      paragraph2: `El tipo más común de pérdida de cabello es la caída de cabello androgénica. En las primeras fases, el cabello se vuelve más delgado (miniaturización) y pierde su pigmento y se vuelve más claro en color y tiene una apariencia débil y sin vida. La pérdida de cabello de patrón masculino, cuya edad de ocurrencia retrocede a la edad de 18 años, comienza con la regresión de la línea del cabello en la frente y se manifiesta como adelgazamiento del cabello en todos los hombres.`,
      paragraph3: `Con algunos medicamentos para usar en este período, se puede prevenir la pérdida de los folículos y estimular las células madre inactivas. Después de que el tipo androgénico de pérdida de cabello alcanza cierto nivel, la única solución es el trasplante capilar. El pelo de la parte superior del cuello y la zona entre las dos orejas no se cae ya que son insensibles a los productos de degradación de la testosterona. Estos pelos se utilizan en el trasplante capilar.
      `,
      paragraph4: `La pérdida de cabello es un problema grave que se ve cada vez más en las mujeres de hoy y afecta la vida social. Hoy en día, el problema de la caída del cabello se presenta en una de cada 10 mujeres. La caída del cabello generalmente se ve en las mujeres como un adelgazamiento y adelgazamiento que afecta a todo el cuero cabelludo.
      `,
      paragraph5: `Mientras que la causa principal de la caída del cabello en los hombres es genética, las causas de la caída del cabello en las mujeres son; Pueden presentarse cambios hormonales, algunas enfermedades como problemas de tiroides, anemia, deficiencias de minerales, especialmente de hierro, estrés, traumatismos.
      `,
      paragraph6: `Para excluir algunos factores sistémicos y hormonales en las mujeres, se investigan las causas de la caída del cabello. Diagnosticar y tratar las causas de la caída del cabello en las mujeres no es tan fácil como en los hombres.
      `,
    },
    SacDokulmesiTedavileri: {
      title1: `TRATAMIENTOS PARA LA`,
      title2: `CAÍDA DEL CABELLO`,
      subInfo: `En nuestra clínica se aplican diferentes métodos de tratamiento para prevenir la caída del pelo.`,
      subTitle1: `TRATAMIENTO DE PRP`,
      paragraph1: `Tratamiento Capilar P.R.P. En otras palabras, el "Plasma Rico en Plaquetas" es la aplicación de plaquetas obtenidas de su propia sangre, a través de procesos especiales, en zona receptora durante y después del trasplante capilar, o en áreas que se vuelven despobladas durante las fases de caida del cabello. 
      `,
      paragraph2: `Se ha observado que la tasa de adherencia de los folículos en la zona receptora se aumenta y la cicatrización de heridas se acelara después de la operación de los pacientes de trasplante capilar que se sometieron a tratamiento con PRP. Se puede aplicar con fines de tratamiento a los hombres o las mujeres que tienen el pelo debil pero no han perdido completamente, debido a razones genéticas o estacionales.
      `,
      paragraph3: `Además, se obtienen resultados exitosos en la enfermedad alopecia, de pérdida de cabello regional, que se denomina 'Areata Androgénica'. El PRP se puede aplicar a todos los casos de trasplante capilar. Dado que este procedimiento, realizado con su propia sangre, no contiene otras sustancias, no existe absolutamente ningún riesgo de reacción alérgica o infección después del tratamiento.
      `,
      paragraph4: `El P.R.P o "Plasma Rico en Plaquetas" es un subproducto de la sangre que se ha utilizado para acelerar la cicatrización de heridas en todo el mundo desde la década de 1970. Este efecto de P.R.P ha sido aprobado por la Administración de Alimentos y Medicamentos de los Estados Unidos (FDA)
      `,
      subTitle2: `Mesoterapia Capilar
      `,
      paragraph5: `La mesoterapia capilar es un método de tratamiento que se aplica en el cuero cabelludo para detener la caída del cabello y activar algunos de los folículos inactivos debajo de la piel y revitalizarlos.
      `,
      paragraph6: `La mesoterapia capilar es la inyección de mezclas de fármacos seleccionadas en la capa profunda de la dermis del cuero cabelludo con pequeñas dosis de agujas especiales y una técnica especial, determinando la causa de la caída. Esta fórmula especial de mezcla de aminoácidos, vitaminas y minerales que da la nutrición a las células madre activadas. Por lo tanto, en lugar de los tratamientos con menos efectos, con estructuras permanentes a largo plazo, el cabello debilitado y sin vida se puede transformar de nuevo en un estado saludable, se puede activar la circulación sanguínea en el cuero cabelludo, se puede regular el drenaje linfático y se puede fortalecer la estructura del colágeno. `,
      paragraph7: `Para el tratamiento de la caída del cabello, las sesiones de mesoterapia, que se aplican para nutrir el metabolismo acelerado de todas las células madre activadas, dura 15 minutos y son aplicadas por nuestros expertos.
      `,
      subTitle3: `Finasterida – Tratamiento Medical`,
      paragraph8: `La finasterida es un inhibidor de la 5α-reductasa tipo 2. Esto reduce la conversión de testosterona en dehidrotestosterona (DHT).
      `,
      paragraph9: `Se observan un cambio positivo significativo en número y en la calidad del pelo en el 80% de las personas que usan finasterida. No solo se mejora el cabello debilitado, sino que también detiene la caída del cabello. El pelo ya perdido no se vuelve pero tampoco provoca el crecimiento de vello en el cuerpo.
      `,
      paragraph10: `Los resultados se ven en unos 4-6 meses después de su uso. Debe usarse durante 2 años para tener el efecto máximo. Se recomienda usarlo con regularidad para mantener su efecto.
      `,
      paragraph11: `Finasteride es efectivo en todas las áreas del cabello, pero es más efectivo en la coronılla y la parte frontal.
      `,
      paragraph12: `La dosis recomendada de finasterida es de 1 mg al día. No hay una interaccion conocida con medicamentos.
      `,
      paragraph13: `La finasterida no se recomienda a las mujeres embarazadas ni a los pacientes con enfermedad hepática.
      `,
      paragraph14: `Los efectos secundarios conocidos de la finasterida son siguientes: Disminución o aumento de los parametros a las tasas indicadas:
      La libido (1,9 %), disfunción eréctil (1,3 %), disfunción de la eyaculación (1 %). 
      En algunos pacientes, los efectos secundarios persisten durante unos 3 meses después de suspender el uso. Finasteride funciona para reducir los niveles de antígeno prostático específico (APE) y rara vez puede causar ginecomastia. Finasteride es el primer medicamento aprobado por la FDA para el cabello. Se utiliza principalmente para equilibrar la caída del cabello, proteger el cabello existente y reducir la caída del cabello.`,
      subTitle4: `Minoxidil – Tratamiento Medical`,
      paragraph15: `Aunque se aplica hoy en día, nuestra clínica no lo recomienda.`,
    },
    KasEkimi: {
      title: 'TRASPLANTE DE CEJAS',
      paragraph1: `Los métodos del trasplante de cejas son los mismos que los del trasplante capilar.
      `,
      paragraph2: `Los folículos extraidos con el método FUE, se implantan al área donde esta despoblada de la ceja,  con la ayuda de unas pinzas especiales, teniendo en cuenta a la naturalidad. 
      `,
      paragraph3: `El trasplante de cejas tarda 4/5 horas. Después de la operación, el paciente puede volver a su vida diaria.`,
    },
    SakalEkimi: {
      title1: `Trasplante de`,
      title2: `Barba / Bigote`,
      paragraph1: `Los métodos del trasplante de barba y/o bigote tienen los mismos principales que los del trasplante capilar.
      `,
      paragraph2: `Los folículos extraidos con el método FUE se implantan la zone despoblada de la barba y el bigote con la ayuda de unas pinzas especiales.
      `,
      paragraph3: `Dado que el área facial tiene una estructura sensible, las personas con experiencia deben realizar las intervenciones.`,
    },
    FooterForm: {
      title: `Solicite valoracion`,
      name: `Nombre`,
      email: 'E-Mail',
      phone: 'Teléfono',
      nationality: 'Nacionalidad',
      subject: 'Pregunta ',
      message: 'Mensaje',
      send: 'Envíe',
    },
    Fue: {
      title1: 'FUE',
      title2: '(Extracción de',
      title3: 'los unidades foliculares)',
      top: {
        paragraph1:
          'FUE es el método utilizado en la extracción de folículos en el trasplante capilar y es el último desarrollo en el campo de la cirugía de trasplante capilar.',
        paragraph2:
          'Con la técnica FUE, los injertos se recolectan de la zona donante (el área entre las dos orejas donde se extrae el pelo) con micro motores.',
        paragraph3:
          'La técnica FUE consiste en la extracción de un gran número de folículos de la máxima calidad, uno por uno, sin dejar cicatrices en la zona donante. En la técnica FUE no se hace ninguna incisión en el cuero cabelludo.',
        paragraph4:
          'El método FUE permite extraer más injertos en comparación con otras técnicas. El método FUE aumenta la cantidad de injertos a trasplantar en comparación con otros métodos y permite que el paciente tenga el pelo más denso después de trasplante capilar.',
      },

      benefitTitle: 'Beneficios del Método FUE',

      benefits: {
        left: [
          'No requiere puntos de sutura y no deja cicatrices permanentes.',
          'Uno tiene el proceso de cicatrización cómodo y de corto plazo.',
          'En general no se presentan edemas ni hematomas.',
          'El dolor postoperatorio está casi ausente.',
        ],
        right: [
          'Después de la operación, la actividad es menos limitada y se vuelve a la vida diaria facilmente.',
          'Se puede extraer la máxima cantidad de los folículos.',
          'El paciente puede tener su pelo como prefiera, largo o corto.',
        ],
      },
      info1: `La extracción de folículos/injertos con el método FUE se puede aplicar con dos técnicas, FUE Manual y FUE con Micromotor.`,
      info2:
        'Manuel FUE ve Micromotor FUE olmak üzere iki teknik ile uygulanabilmektedir.',
      manuel: {
        title: 'LOS MÉTODOS DE FUE',
        list: [
          'Sin el uso de una herramienta motorizada,',
          `Con la ayuda de punzones especiales con coordinación completa de manos y ojos, los folículos se extraen uno por uno, 
          `,
          `Sin pérdida de injerto,
          `,
          `Se proporciona un trasplante cómodo y una recuperación a corto plazo,
          `,
          `Sin dejar cicartiz permanente, no provoca edemas ni hematomas, 
          `,
          `Es una técnica que nos ayuda obtener un aspecto natural (sobre todo una imagen homogénea en la zona donante).
          `,
        ],
        paragraph: [
          `La técnica Manual Fue requiere un toque delicado, resistencia física, excelente coordinación mano-ojo y habilidades motoras de la mano.
          `,
          `El objetivo principal de la técnica FUE manual es evitar que los injertos se dañen durante el proceso de extracción y conservar la homogeneidad de la zona donante. Dado que se conserva la homogeneidad, incluso después de más de una sesión, la densidad en la zona donante solo se puede ver con un examen detallado.
          `,
          `Dado que la estructura de la cabeza de cada paciente, la dirección y el ángulo del pelo pueden ser diferentes entre sí, se proporciona una apariencia natural al hacer la planificación individualmente.`,
        ],
      },
      micromotor: {
        title: 'FUE CON MICROMOTOR',
        list: [
          `Con la ayuda de los punzones especiales utilizando una herramienta motorizada para extraer los injertos/folículos uno por uno,
          `,
          `Permitiendo la máxima eliminación de raíz de injerto/cabello en un solo día,
          `,
          `Sin dejar cicatriz permanente, 
          `,
          `Es una técnica que requiere un equipo con mucha experiencia.
          `,
        ],
        paragraph: [
          `En la técnica de Micromotor Fue, el equipo debe tener experiencia y la selección del punch debe ser correcta en la zona donante, de lo contrario se ve la pérdida del injerto y se puede observar la cicatriz en zona donante.
          `,
          `El objetivo principal de la técnica FUE con micromotor es extraer el máximo número de injertos/folículos en un día.
          `,
          `Dado que la estructura de la cabeza de cada paciente, la dirección y el ángulo del pelo pueden ser diferentes entre sí, se proporciona una apariencia natural al hacer la planificación individualmente.`,
        ],
      },
      shaved: {
        title: 'FUE MANUAL SIN AFEITAR',
        paragraph: [
          `El FUE manual sin afeitar es el proceso de extraer los folículos de la zona donante en su forma larga sin acortar el pelo y implantar los folículos en los canales del Lateral Slit en la zona receptora.
          `,
          `La operación de FUE manual sin afeitar es un procedimiento complejo que requiere una gran cantidad de experiencia y habilidad; realizar la operación con los instrumentos quirúrgicos adecuados conducirá a resultados exitosos.
          `,
          `El procedimiento de FUE manual sin afeitar se recomienda para la gente que necesita 3000 folículos o menos en una sola sesión, ya que requiere experiencia, ser muy cuidadoso y es un proceso que requiere mucho tiempo.`,
        ],
      },
      insizyon: {
        title: 'INCISIÓN (APERTURA DE LOS CÁNALES)',
        paragraph: [
          `La apertura de los cánales es una de las técnicas que se hace para implantar los folículos extraídos de la zona donante a la zona a trasplantar.
          `,
          `Los cánales son las incisiones en la zona receptora para colocar los injertos extraídos de la zona receptora.
          `,
          `La apertura de los cánales se puede realizar con el método Corto Lateral o Corto Sagittal en las operaciones de trasplante capilar. 
          `,
          `Sin embargo, aunque el proceso de implantación sea más largo y laborioso, en nuestro centro se realiza la incisión con el método "Corto Lateral" para garantizar la naturalidad en la zona receptora.
          `,
        ],
      },
      leteral: {
        title: 'EL CORTO LATERAL ',
        paragraph: [
          `El corto lateral es una incisión hecha en la dirección del crecimiento del cabello. La técnica lateral proporciona el aspecto natural del pelo. El ángulo del pelo normalmente varía de ángulos de 10° a 90°. Con la tecnica del corto lateral, se obtiene más densidad al usar incisiones más pequeñas.
          `,
          `La dirección, el ángulo, el espacio y la profundidad del crecimiento del pelo son extremadamente importantes para obtener un aspecto natural.
          `,
        ],
        list: [
          `Orientación natural,
          `,
          `Menos daño tisular`,
          `Colocación cercana del injerto y`,
          `Proporciona la ilusión de más volumen.
          `,
        ],
      },
      implantasyon: {
        title: 'IMPLANTACIÓN',
        paragraph: [
          `La implantación es el proceso de colocar los injertos extraídos con la técnica FUE Manual o FUE con Micromotor en las incisiones hechas con el método Corto Lateral, sin dañar los folículos con herramientas técnicas especiales.
          `,
        ],
      },
      sedasyon: {
        title: 'TRASPLANTE CAPILAR CON SEDACIÓN',
        paragraph: [
          `Es un método de anestesia desarrollado para garantizar que el procedimiento de trasplante capilar  sea indoloro.
          `,
          `El procedimiento de sedación es aplicado por el anestesiólogo.
          `,
          `Después de la aplicación, el paciente está consciente y es posible hablar con el paciente.
          `,
          `Especialmente, el trasplante capilar con sedación se recomienda a las personas con problemas de ataques de pánico, que están nerviosos por el proceso de inyección, etc. 
          `,
        ],
      },
      implantasyonDHI: {
        title: 'TRASPLANTE CAPILAR CON DHI',
        paragraph: [
          `DHI es una de las técnicas para implantar los injertos/folículos pilosos extraídos de la zona donante a la zona a trasplantar.`,
          `Los folículos  extraídos con el método Fue se transfieren al área con implantadores especiales llamados Choi.
          `,
          `En la técnica DHI, los folículos se pueden colocar en un solo procedimiento (incisión y implantación del folículo).
          `,
          `Los folículos recolectados con el implantador choi, se colocan en este bolígrafo con una aguja, uno por uno con cuidado.
          `,
          `Después de determinar el ángulo más adecuado para implantar y determinar la dirección correcta, los folículos se implantan a la zona receptora.
          `,
          `La implantación se realiza con la función de empuje de la aguja.
          `,
          `La cantidad de los implantadores utilizados en la operación y la cantidad de puntas de los implantadores varían según el folículo piloso y la estructura del grosor del pelo.`,
        ],
      },
    },
    OnlineReservation: {
      title: 'Póngase en contacto con nosotros',
      subInfo: `Ofrecemos la consulta gratis con nuestro equipo de expertos y traductores a nuestros pacientes por el trasplante capilar, barba, bigote y cejas. Llene el siguiente formulario para consultas profesionales y más información.`,
      formTitle: 'Consulta y Formulario de Consulta',
      list: [
        `* Para poder ofrecerle una consulta efectiva, por favor complete correctamente las preguntas en las secciones a continuación.
        `,
        `* En la sección "adjunte la foto" a continuación, carge las fotos de la zona donante (el área entre las dos orejas) y el área despoblado (las fotos deben ser claras y de buena calidad) como se indica en los ejemplos.`,
      ],
      labels: {
        name: 'Nombre y Apellido',
        birthDate: 'La Fecha de Nacimiento',
        country: 'La Ciudad / El País',
        phone: 'El teléfono',
        email: 'E-Mail',
        job: 'La Profesión',
        reference: 'La Referancía / Dónde nos encontró?',
        patientsAdvice: 'La recomendación de un paciente',
        socialMedia: 'La media social',
        forum: 'El foro',
        magazine: 'Revista/Periódico',
        internet: 'İnternet',
        other: 'Otro',
        transplantTitle: '¿Cuál es su preferencia del método FUE?',
        hair: 'Trasplante Capilar',
        beard: 'Trasplante de Barba',
        mustache: 'Trasplante de Bigote',
        eyebrow: 'Trasplante de Cejas',
        informTransplantTitle: `Especifique las zonas donde quiere más densidad con el trasplante capilar. ¿Cuáles son sus expectativas sobre el resultado del trasplante capilar?`,
        fueTitle: '¿Cul es su preferencia del método FUE?',
        manuelFue: 'FUE Manual',
        micromotorFue: 'FUE con Micromotor',
        micromotorShaved: 'Trasplante capilar / afeitado',
        micromotorUnShaved: 'Trasplante capilar sin afeitar',
        shavedInfo: `Se recomienda preferir el trasplante capilar sin afeitar en los casos en que la zona despoblada es pequeña y se puede cubrir la zona con 3000 o menos injertos.`,
        transplantTypeTitle: `¿Cuál es su preferencia de la implantación?`,
        leteral: 'Trasplante Capilar con Corte Lateral ',
        dhi: 'Trasplante Capilar con el metódo DHI',
        sedasyon: 'Trasplante Capilar con sedación',
        hairLossMedicine: `¿Hay algún medicamento que use para prevenir la caída del pelo? En caso afirmativo, informe el medicamento que usa.`,
        transplantBefore: `¿Ha tenido un trasplante antes? Si es afirmativa, describa su opinión sobre la clínica, el médico, la técnica utilizada, la cantidad de injertos trasplantados y el resultado del trasplante`,
        hadAnyIllness: `¿Tiene alguna enfermedad?`,
        none: 'No',
        verem: 'Tuberculosis',
        tansiyon: 'presión arterial',
        heart: 'Enfermedades cardíacas',
        seker: 'Diabetes',
        eklem: 'Reumatismo Articular',
        epilepsi: 'Epilepsia',
        romatizma: 'Reumatismo ardiente',
        zuhrevi: 'Enfermedad venérea',
        guatr: 'Bocio (tiroides)',
        blood: 'Enfermedades de la Sangre (Hepatitis B - C)',
        bobrek: 'Enfermedades renales/hepáticas',
        astim: 'Asma',
        saman: 'Fiebre del heno',
        sarilik: 'Ictericia',
        akciger: 'Enfermedades pulmonares',
        aids: 'SIDA',
        treatmentTitle:
          '¿Está recibiendo algún tratamiento actualmente? ¿Está usando medicación?',
        alergicTo:
          '¿Hay algún medicamento al que sea alérgico? Por favor especifique.',
        antibiyotik: 'Alergia a los antibióticos',
        painkiller: 'Alergia a los analgésicos',
        anestezik: 'Alergia a medicamentos anestésicos',
        localTitle: '¿Ha tenido una operación con anestesia local antes?',
        yes: 'Si',
        no: 'No',
        yesBut: 'En caso afirmativo, información de la operación',
        skinProblem: '¿Tiene algún problema de la piel?',
        dermatit: 'Dermatitis',
        dermatitQuote: '(eccema)',
        desquamasyon: 'Descamación',
        desquamasyonQuote: '(caspa)',
        folikulit: 'Foliculitis',
        folikulitQuote: '(Inflamación del folículo piloso)',
        hipertrofik: 'Hipertrófico',
        keloid: 'Queloide',
        photoAddTitle: 'Sección para agregar fotos',
        send: 'Enviar',
      },
    },
    OperasyonSonrasi: {
      title1: 'LOS CUIDADOS',
      title2: 'DESPUÉS DE',
      title3: 'LA OPERACIÓN',
      title4: '',
      list1: [
        `Use los medicamentos recetados por su médico regularmente según las recomendaciones. (antibióticos y analgésicos, etc.)
`,
        `Debe dormir boca arriba con una collarín durante 5 (cinco) días después de la operación. (Girar a la derecha, a la izquierda o acostarse boca abajo puede dañar la zona receptora).`,
        `Evite el contacto sexual durante los 5 (cinco) días después de la operación.`,
        `No consuma alcohol ni cigarrillos durante (una) semana.`,
        `Protega la zona receptora de las gotas de lluvia y del sol por 10 (diez) días.
        `,
        `Está prohibido el uso de sombreros y bandanas durante 10 (diez) días.
        `,
      ],
      list2: [
        `Durante 10 (diez) días, se debe preferir ropas con cremalleras o botones.
        `,
        `Deportes, solárium, sauna, baño turco, jacuzzi y baños de sol están prohibidos durante 1 (un) mes después de la operación.
        `,
        `Tenga cuidado de no dañar la zona receptora durante 6 (seis) meses, especialmente durante los primeros 10 (diez) días.
        `,
        `No se recomenda teñir el pelo durante 6 (seis) meses, no usar gel ni spray, la máquina no debe ser utilizada durante 6 (seis) meses. Se recomienda cortar el cabello con tijera.`,
        `Se recomienda utilizar propecia durante 2 (dos) años y se debe usarlo continuado para mantener su efecto. Propecia no solo fortalece el pelo existente y evita que se caiga, sino que también espesa el pelo existente y trasplantado.
        `,
      ],
      title5: 'INFORMACIÓN Y PROCESO DE LAVADO DESPUÉS DE LA OPERACIÓN',
      list3: [
        `No se preocupe si tenga edema/hinchazón en la cara. El edema/hinchazón desaparecerá en 3 (tres) días. Para evitar edemas/hinchazones, aplique regularmente el gel de hielo que le da nuestra clínica y tenga cuidado de no inclinar la cabeza hacia adelante.
        `,
        `Si se ocurre el picazón y/o brotes de acné en la zona receptora, póngase en contacto con nuestra clínica.
        `,
      ],
      list4: [
        `Haga sus lavados regularmente durante 10 (diez) días después de la operación y limpie las costras que pueden ocurrir en la zona receptora al 10 (décimo) día según las explicaciones.
        `,
      ],
      title6: 'El lavado debe hacer como se indica a continuación.',
      list5: [
        `En primer lugar, aplique la loción recomendada a la zona receptora y espere por 15 minutos.
        `,
        `Limpie la loción después 15 minutos con toquecitos con agua tibia sin presión. No haga movimientos fuertes de ida y vuelta a la zona de implantación.
        `,
        `El champú se debe hacer espuma frotándolo entre las manos, se aplica en la zona receptora y donante con toquesitos y aclarar con agua tibia sin presión. Repite el proceso de lavado con champú y enjuague dos veces.
        `,
      ],
      list6: [
        `Debe secar la zona receptora con toques suave y el papel de cocina.
        `,
        `Asegúrese de usar el champú recomendado, especialmente durante los primeros 10 (diez) días. Después de 10 (diez) días, cuando termine el champú recomendado, puede usar cualquier champú que desee.`,
      ],
    },
  },
  ARB: {
    Global: {
      Header: {
        homepage: 'الصفحة الرئيسية',
        about1: 'S&S MED',
        about2: 'حول',
        aboutTransplant1: 'حول زراعة الشعر',
        aboutTransplant2: '',
        clinic: 'العيادة',
        onlineReservation1: 'الحجز',
        onlineReservation2: '',
        contact: 'التواصل',
        institutional: 'مؤسسة',
        hairLoss: 'أسباب تساقط الشعر',
        medicalTreatment: 'العلاج الطبي',
        fue: 'زراعة الشعر بالاقتطاف FUE',
        eyebrow: 'زراعة الحاجب',
        beard: 'زراعة اللحية والشارب',
        postOperation: 'تعليمات ما بعد العملية',
        fueInner: {
          manuel: 'زراعة الشعر بـالاقتطاف اليدوي',
          micromotor: 'Micromotor FUE زراعة الشعر بــــ',
          unshaved: 'الاقتطاف يدوي FUE بدون حلاقة',
          leteral: 'زراعة الشعر بالشق الجانبي',
          dhi: 'DHI زراعة الشعر بـــ',
          sedasyon: 'زراعة الشعر بالتخدير',
        },
      },
    },
    Home: {
      HeadInfo: {
        title: 'هل أنت مستعد للتغيير و التجديد؟',
        paragraph1: `باستخدام تقنية FUE)  FUE اليدوية و / أو Micromotor FUE) المطبقة من قبل فريق الخبراء لدينا في المركز، يتم إجراء زراعة الشعر ,اللحية ,الشارب أوالحواجب مع الحلق و / أو بدون حلاقة وفقًا لخصائص الشخص الذي سيخضع لعملية الزرع . تتم عملية زراعة الشعر واللحية والشارب والحواجب في ثلاث خطوات: الاستخراج (عملية إزالة بصيلات الشعر) ، والشق (عملية فتح شق / قناة في المنطقة التي ستُزرع بها الطعوم) ، والزرع (عملية الزراعة).`,
        paragraph2: `نقرر مع مرضانا أي تقنية FUE التي سيتم تطبيقها في عمليات زراعة الشعر ,اللحية ,الشارب أو الحواجب.`,
      },
      Fue: {
        title: 'FUE  طريقة',
        subTitle1: 'تقنية الإقتطاف اليدوي',
        subTitle2: 'Micromotor FUE   تقنية الإقتطاف',
        paragraph1: `تعتبر زراعة الشعر , اللحية , الشارب وزراعة الحاجب من العمليات المعقدة التي تتضمن عناصر مهمة. النتائج الإيجابية لعملية الزراعة ؛ يعتمد ذلك على : خصائص المريض ، التصميم ، إزالة البُصيلات ، الحفاظ على البُصيلات ، عملية الشق ، الزرع ، الرعاية بعد الجراحة والتطبيق الدقيق و المناسب للعلاج.`,
        paragraph2: `يتم تحليل و تدقيق كل مريض بعناية من قبل طاقمنا المتمرس باستخدام معدات تقنية متطورة ويتم تخطيط و تحديد عملية زراعة الشعر.`,
        paragraph3: `يتم إجراء تحليل الدم وتخطيط القلب قبل الجراحة لكل مريض.`,
        paragraph4: `يتم تحديد وتطبيق إجراءات التخدير اللازمة أثناء العملية من قبل طبيب التخدير لدينا في المركز.`,
        paragraph5: `في مركزنا ، يتم استخدام أدوات جراحية عالية الجودة ويتم التحكم في الأدوات الجراحية وتعقيمها بانتظام.`,
        paragraph6: `يتم التحكم بعناية في العوامل التي تؤثر على البُصيلات  بأحدث التقنيات. تبقى الطعوم حية لفترة طويلة عن طريق إبقائها في سائل خاص (hypothermosol) وسوائل ATP. وبالتالي ، لا يحدث فقدان للبُصيلات.`,
        paragraph7: `مترجمنا الذي يعمل في المركز يرافق المريض أثناء و بعد العملية.`,
      },
    },
    About: {
      title: 'حول S&S MED',
      paragraph1: `S&S MED هي مؤسسة رعاية صحية تقدم خدمات موجهة لإرضاء المرضى في مستشفى Atakent Acıbadem ، والتي أبرمت معها عقدًا مع فرق متخصصة من ذوي الخبرة حيث جمعت خبرتهم في قطاع الصحة وزراعة الشعر.`,
      paragraph2: `لمبدأ الأساسي لمركزنا هو متابعة التطورات على الساحة الدولية في مجال زراعة الشعر وعلاجات تساقط الشعر من قبل فريقنا المتخصص، في الإطار القواعد الأخلاقية و وفقًا لخصائص الشخص الذي سيخضع لعملية الزرع يتم تحديد , تقييم وتطبيق الحل الأنسب للمريض.`,
      paragraph3: `هدفنا الرئيسي هو خلق مظهر كثيف وطبيعي مع زراعة الشعر الأمثل.`,
      paragraph4: `يمكن لموظفينا الخبراء تطبيق أساليب وتقنيات مختلفة وفقًا لخصائص وتفضيلات كل مريض. `,
      paragraph5: `يحضر خبراؤنا المؤتمرات المحلية والدولية , حيث شارك وقام بدور نشط في كلاً من : 
      ISHRS 2nd Mediterranean FUE Workshop Madrid / Spain 24-26 May 2013
      ISHRS European Hair Transplant Seminar Brussels / Belgium 13-15 June 2014
      3. Mediterranean FUE Workshop İstanbul/Turkey 25-27 June 2015
      استمرارًا للخدمة منذ 13عامًا من الخبرة والتجربة في مجال جراحة زراعة الشعر ، شارك خبراؤنا في الآلاف من عمليات زراعة الشعر ، بالإضافة إلى عمليات زراعة الحاجب والشارب واللحية برفقة جراحي التجميل.

      `,
    },
    SacDokulmesiNedenleri: {
      title1: 'تساقط الشعر وأسبابه',
      title2: '',
      subTitle1Part1: `تساقط الشعر عند الذكور `,
      subTitle1Part2: ``,
      subTitle2: `تساقط الشعر عند النساء`,
      paragraph1: `أهم أسباب تساقط الشعر هي العوامل الوراثية. يعتبر تساقط الشعر في الأسرة مشكلة كبيرة تصيب سبعة من كل عشرة رجال ابتداء من سن 18 ، وهي أقل شيوعًا عند النساء ، وتؤذي الشخص جسديًا و نفسياً واجتماعيًا.`,
      paragraph2: `أكثر أنواع تساقط الشعر شيوعًا هو تساقط الشعر الأندروجيني. في المراحل المبكرة من التساقط ، يصبح الشعر رقيق ويفقد صبغته ويصبح أفتح لونًا ويكون مظهره ضعيفًا وبلا حياة. 
      يبدأ النمط الذكوري من تساقط الشعر بالظهور في سن 18 عام ، حيث يتسع خط شعر في الجبهة ويظهر على هيئة ترقق و خِفة في الشعر عند جميع الرجال.`,
      paragraph3: `مع بعض الأدوية التي يوصى باستخدامها في هذه الفترة والتحفيز الحيوي بالليزر ، يمكن منع فقدان بصيلات الشعر وتحفيز الخلايا الجذعية الغير النشطة. بعد أن يصل تساقط الشعر الأندروجيني إلى مستوى معين ، يكون الحل الوحيد هو زراعة الشعر. لا يتساقط الشعر الموجود في الجزء العلوي من الرقبة والمنطقة الواقعة بين الأذنين لأنها غير حساسة لتكسر هرمون التستوستيرون. حيث تستخدم هذه المنطقة من الشعيرات في زراعة الشعر.`,
      paragraph4: `يعتبر تساقط الشعر مشكلة كبيرة تظهر بشكل متزايد لدى النساء في يومنا هذا وتؤثر على الحياة الاجتماعية. حيث أن اليوم ، تظهر مشكلة تساقط الشعر في واحدة من كل 10 نساء. يُنظر إلى تساقط الشعر عمومًا عند النساء على أنه ترقق وخِفة الشعر و يؤثر على فروة الرأس بأكملها.`,
      paragraph5: `في حين أن السبب الرئيسي لتساقط الشعر عند الرجال وراثي ، فإن أسباب تساقط الشعر عند النساء هي ؛ التغيرات الهرمونية  او بعض الأمراض مثل مشاكل الغدة الدرقية ، وفقر الدم ، ونقص المعادن ، وخاصة الحديد ، والإجهاد ، والصدمات النفسية.`,
      paragraph6: `عند النساء ، يتم التحقيق في أسباب تساقط الشعر من أجل استبعاد بعض العوامل العضوية والهرمونية. ليس من السهل تشخيص وعلاج أسباب تساقط الشعر عند النساء كما هو الحال عند الرجال.`,
    },
    SacDokulmesiTedavileri: {
      title1: `علاجات تساقط الشعر`,
      title2: ``,
      subInfo: `في عيادتنا ، لدينا طرق مختلفة في علاج تساقط الشعر.`,
      subTitle1: `علاج الشعر بالبلازما الغنية بالصفائح الدموية PRP`,
      paragraph1: `PRP بمعنى آخر ، "البلازما الغنية بالصفائح الدموية" هي عبارة عن حقن للصفائح الدموية التي يتم الحصول عليها من دمك ، من خلال عمليات خاصة ، إلى المنطقة المزروعة أثناء وبعد زراعة الشعر ، أو إلى المناطق التي بها شعر متناثر و خفيف أثناء مراحل تساقط الشعر.`,
      paragraph2: `لوحظ ارتفاع معدل ثبات البصيلات في المنطقة المزروعة وتسارع التئام الجروح بعد العملية لدى مرضى زراعة الشعر الذين خضعوا للعلاج بالبلازما الغنية بالصفائح الدموية. يمكن استخدامه لأغراض العلاج لدى الرجال أو النساء الذين يعانون من خفة و تناثر في الشعر و عدم تساقط الشعر كامل لأسباب وراثية أو موسمية.`,
      paragraph3: `بالإضافة إلى ذلك ، تم الحصول على نتائج ناجحة في مرض "الثعلبة" ، والذي يسمى " التساقط الأندروجيني ".`,
      paragraph4: `يمكن تطبيق نظام PRP على كل حالة زراعة شعر.
      نظرًا لأن هذا الإجراء ، الذي يتم اخذه من دمك ، أي لا يحتوي على مواد أخرى ، فلا يوجد أي خطر على الإطلاق من حدوث تفاعل تحسسي أو عدوى بعد العلاج.
      P.R.P أو "البلازما الغنية بالصفائح الدموية" هي منتج ثانوي للدم تم استخدامه لتسريع التئام الجروح في جميع أنحاء العالم منذ السبعينيات. تمت الموافقة على تأثير P.R.P من قبل FDA (إدارة الغذاء والدواء) في الولايات المتحدة.
      `,
      subTitle2: `ميزوثيرابي للشعر`,
      paragraph5: `ميزوثيرابي الشعر هو طريقة علاجية تطبق على فروة الرأس لوقف تساقط الشعر وتنشيط بعض بصيلات الشعر الخاملة تحت الجلد وتنشيطها.`,
      paragraph6: `ميزوثيرابي الشعر هو حقن أدوية مختارة في طبقة الأدمة العميقة من فروة الرأس ، بعد تحديد سبب التساقط ,بجرعات صغيرة من إبر خاصة وتقنية خاصة. توفر هذه التركيبة الخاصة من الأحماض الأمينية والفيتامينات والمعادن تغذية الخلايا الجذعية المنشطة. وبالتالي ، بدلاً من التغييرات قصيرة المدى ، فيصبح تغيَر دائم و طويل المدى ، يمكن تحويل الشعر الضعيف وغير الحي إلى حالة أكثر صحة ، ويمكن تنشيط الدورة الدموية في فروة الرأس ، وتنظيم التصريف اللمفاوي ، و تقوية بنية الكولاجين .`,
      paragraph7: `لعلاج تساقط الشعر ، جلسات الميزوثيرابي ، والتي يتم تطبيقها لتغذية التمثيل الغذائي لجميع الخلايا الجذعية المنشطة ، تستمر الجلسة لمدة 15 دقيقة ويتم تطبيقها من قبل خبرائنا المتمرسين.`,
      subTitle3: `فيناسترايد - Finansterid  العلاج الطبي`,
      paragraph8: `فيناسترايد هو النوع الثاني من المثبط 5 ريداكتيز المختزل. حيث انه يقلل من تحويل هرمون التستوستيرون إلى ديهدروتستوستيرون (DHT).`,
      paragraph9: `لوحظت زيادات كبيرة في عدد وكثافة الشعر في 80٪ من الأشخاص الذين يستخدمون فيناسترايد. فهو لا يثخن الشعر الخفيف فحسب ، بل يوقف تساقط الشعر أيضًا. لا يستعيد الشعر المفقود. لا يسبب نمو الشعر على الجسم.`,
      paragraph10: `يبدأ تأثيره في حوالي 4-6 أشهر بعد استخدامه. أي يجب استخدامه لمدة عامين لتحقيق أقصى تأثير له . و يوصى بالاستخدام المستمر للحفاظ على تأثيره.`,
      paragraph11: `فيناسترايد فعال في جميع مناطق الشعر الخفيف ، لكنه أكثر فعالية في المناطق الوسطى والعليا من فروة الرأس.`,
      paragraph12: `الجرعة الموصى بها من فيناسترايد هي 1ملغ في اليوم. لا توجد تفاعلات دوائية معروفة.`,
      paragraph13: `لا ينبغي أن تستخدم فيناسترايد من قبل النساء الحوامل والمرضى الذين يعانون من أمراض الكبد.`,
      paragraph14: `الآثار الجانبية المبلغ عنها للفيناسترايد هي: انخفاض أو زيادة بالمعدلات المشار إليها في الرغبة الجنسية (1,9٪) ، ضعف الانتصاب (1.3٪) ، وضعف القذف (1٪). في بعض المرضى ، تستمر الآثار الجانبية للأدوية لمدة 3 أشهر بعد التوقف عن الاستخدام. يعمل فيناسترايد على تقليل مستويات مستضد البروستاتا النوعي (PSA) ونادرًا ما يسبب التثدي عند الرجال. فيناسترايد هو أول دواء معتمد من قبل إدارة الغذاء والدواء الأمريكية للشعر. في المقام الأول ، يتم استخدامه لموازنة تساقط الشعر وحماية الشعر الموجود وتقليل تساقط الشعر.`,
      subTitle4: `مينوكسيديل - علاج طبي`,
      paragraph15: `على الرغم من أنه يتم تطبيقه في هذه الايام ، إلا أنه لا ينصح به من قبل عيادتنا.`,
    },
    KasEkimi: {
      title: 'زراعة الحاجب',
      paragraph1: `طرق عملية زراعة الحاجب هي نفس طرق زراعة الشعر.`,
      paragraph2: `يتم نقل بصيلات الشعر المأخوذة بطريقة FUE إلى منطقة الحاجب الغير كثيفة أو التي لا تحتوي على شعر ، وذلك بمساعدة ملقط خاص مع مراعاة الحفاظ على طبيعتها.`,
      paragraph3: `تستغرق عملية زراعة الحاجب في المتوسط ​​  4-5ساعات. بعد العملية يمكن للمريض مواصلة حياته اليومية بشكل طبيعي.`,
    },
    SakalEkimi: {
      title1: `زرع اللحية / الشارب`,
      title2: ``,
      paragraph1: `طرق تطبيق زراعة اللحية و / أو الشارب هي نفس طرق زراعة الشعر.`,
      paragraph2: `يتم نقل بصيلات الشعر المأخوذة بطريقة FUE إلى منطقة اللحية والشارب بمساعدة ملقط خاص.`,
      paragraph3: `نظرًا لأن منطقة الوجه لها بنية حساسة ، يجب أن يتم تنفيذ التطبيقات بواسطة أشخاص ذوي خبرة.`,
    },
    FooterForm: {
      title: `الاستشارة المجانية`,
      name: `الاسم`,
      email: 'الايميل ',
      phone: 'الهاتف ',
      nationality: 'الجنسية',
      subject: 'الموضوع',
      message: 'الرسالة',
      send: 'ارسال',
    },
    Fue: {
      title1: '(استخلاص البصيلات)',
      title2: 'FUE',
      title3: '',
      top: {
        paragraph1:
          'FUE هي الطريقة المستخدمة في إزالة البصيلات في زراعة الشعر وهي أحدث التطورات في مجال جراحة زراعة الشعر.',
        paragraph2:
          'في تقنية FUE ، يتم أخذ الطعوم من المنطقة المانحة (المنطقة الواقعة بين الأذنين حيث سيتم أخذ الشعر) باستخدام ملاقط دقيقة.',
        paragraph3:
          'تقنية FUE هي استخراج عدد كبير من الطعوم واحدة تلو الأخرى ، دون ترك أي ندوب في المنطقة المانحة ، مع العدد الأمثل من الطعوم وجودتها. في تقنية FUE ، لا يتم عمل شق في فروة الرأس.',
        paragraph4:
          'تسمح طريقة FUE بأخذ / إزالة المزيد من الطعوم مقارنة بالتقنيات الأخرى. تزيد طريقة FUE من عدد الطعوم المراد زراعتها مقارنة بالطرق الأخرى وتسمح للمريض بالحصول على شعر أكثر كثافة.',
      },

      benefitTitle: 'فوائد طريقة FUE',
      benefits: {
        left: [
          'لا يتطلب أي غرز ولا يترك ندبات دائمة.',
          'وفر عملية شفاء مريحة وقصيرة الأمد.',
          'بشكل عام ، لا تحدث الوذمات والكدمات.',
          'يكاد أن لا يكون ألم ما بعد الجراحة.',
        ],
        right: [
          'بعد العملية العودة السريعة للحياة اليومية.',
          'يمكن إزالة أقصى عدد من الطعوم.',
          'يمكن للمريض أن يستخدم شعره طويلاً أو قصيرًا كما يشاء.',
        ],
      },
      info1: `يمكن تطبيق إزالة بصيلات الشعر/الطعوم بطريقة FUE بتقنيتين ، FUE اليدوي و Micromotor FUE.`,
      info2: '',
      manuel: {
        title: 'FUE اليدوي',
        list: [
          'بدون استخدام أي أداة آلية ،',
          `تتم إزالة بصيلات الشعر واحدة تلو الأخرى بمساعدة ملاقط خاصة بالتنسيق الكامل بين اليد والعين ،`,
          `لا يوجد فقدان في بصيلات الشعر ،`,
          `توفير زراعة مريحة وانتعاش قصير الأمد ،`,
          `لا يترك ندبات دائمة ، ولا يسبب وذمة وكدمات ،`,
          `هي تقنية تسمح بمظهر طبيعي للشعر (تعطي صورة متجانسة في المنطقة المانحة).`,
        ],
        paragraph: [
          `تتطلب تقنية FUE اليدوية لمسًا دقيقًا وتحملًا بدنيًا وتنسيقًا ممتازًا بين اليد والعين ومهارات حركية يدوية.`,
          `الغرض الرئيسي من تقنية FUE اليدوية ليس إتلاف الطعوم والمنطقة المانحة أثناء عملية الاستخراج والحفاظ على تجانس المنطقة المانحة. نظرًا للحفاظ على التجانس ، حتى بعد أكثر من جلسة ، لا يمكن رؤيتها في المنطقة المانحة إلا من خلال الفحص الدقيق.`,
          `نظرًا لأن بنية رأس كل مريض واتجاه وزاوية الشعر قد تختلف عن بعضها البعض ، يتم توفير المظهر الطبيعي من خلال التخطيط الدقيق وفقًا لكل مريض.`,
        ],
      },
      micromotor: {
        title: 'Micromotor Fue',
        list: [
          `يتم استخراج بصيلات الشعر واحدة تلو الأخرى بمساعدة ملاقط خاصة باستخدام أداة آلية ،`,
          `يسمح بإزالة أقصى قدر من بصيلات الشعر في يوم واحد،`,
          `لا تترك ندبات دائمة.`,
          `إنها تقنية يجب أن يقوم بها فريق متمرس.`,
        ],
        paragraph: [
          `في تقنية Micromotor Fue ، يجب أن يكون الممارس من ذوي الخبرة ويجب أن يتم اختيار الثقب بشكل صحيح في المنطقة المراد أخذها ، وإلا فسيحدث فقد الطعوم وتشكيل ندبة في المنطقة المانحة.`,
          `الغرض الرئيسي من تقنية Micromotor FUE هو إزالة أكبر عدد ممكن من الطعوم/بصيلات الشعر في يوم واحد.`,
          `نظرًا لأن بنية رأس كل مريض واتجاه وزاوية الشعر قد تختلف عن بعضها البعض ، يتم توفير المظهر الطبيعي من خلال التخطيط الدقيق وفقًا لكل مريض..`,
        ],
      },
      shaved: {
        title: 'طريقة الإقتطاف اليدوي للشعر بدون حلاقة',
        paragraph: [
          `الاقتطاف اليدوي للشعر بدون حلاقة هو عملية إزالة الطعوم (البُصيلات) من المنطقة المانحة بشكلها الطويل دون تقصير الشعر ووضع الطعوم المأخوذة في القنوات المراد فتحها بطريقة الشق الجانبي في المنطقة المراد زراعتها.`,
          `تعتبر عملية الاقتطاف اليدوي للشعر بدون حلاقة إجراءً معقدًا يتطلب قدرًا كبيرًا من الخبرة والمعرفة والمهارة ، كما أن إجراء العملية باستخدام الأدوات الجراحية المناسبة سيؤدي إلى نتائج ناجحة.`,
          `يوصى باستخدام الاقتطاف اليدوي للشعر بدون حلاقة  , للثغرات التي تتطلب3000  طعمًا أو أقل في جلسة واحدة ، حيث يتطلب وقتًا طويلاً للغاية ويستغرق وقتًا طويلاً في أيدي الخبراء وذوي الخبرة.`,
        ],
      },
      insizyon: {
        title: 'الشق (إنشاء جيوب )',
        paragraph: [
          `إجراء شقوق هي إحدى التقنيات المطبقة لزراعة بصيلات الشعر المأخوذة من المنطقة المانحة إلى المنطقة المراد زراعتها.
          `,
          `الجيب هو الفتحات التي يتم إنشاؤها في منطقة الزراعة لوضع الطعوم المأخوذة من المنطقة المانحة.
          `,
          `يمكن إجراء عملية الشق باستخدام طريقة الشق الجانبي أو طريقة الشق السهمي في زراعة الشعر.
          `,
          `ومع ذلك ، على الرغم من أن عملية الزراعة باستخدام طريقة "الشق الجانبي"  أطول وأكثر مشقة ، يتم إجراء شق باستخدام طريقة "الشق الجانبي" في مركزنا من أجل ضمان شكل طبيعي في منطقة الزراعة.
          `,
        ],
      },
      leteral: {
        title: 'الشق الجانبي ',
        paragraph: [
          `الشق الجانبي هو شق / جيب يتم إجراؤه في اتجاه نمو الشعر. توفر التقنية الجانبية المظهر الطبيعي للشعر. الشعر الطبيعي يتراوح من زاوية 10 ° إلى 90 °. مع الشق الجانبي ، يتم الحصول على كثافة أكبر باستخدام شقوق أصغر.
          `,
          `يعد اتجاه نمو الشعر وزاويته ومساحته وعمقه في غاية الأهمية من أجل الحصول على مظهر طبيعي.
          `,
        ],
        list: [
          `التوجه الطبيعي ،
          `,
          `يحدث تلف أقل للأنسجة ،
          `,
          `فتحات البصيلات متقاربة جداَ و
          `,
          `يوفر مساحة بحجم أكبر.`,
        ],
      },
      implantasyon: {
        title: 'الغرس (عملية التلقيم)',
        paragraph: [
          `الزرع هو عملية وضع الطعوم المستخرجة من المنطقة المانحة باستخدام تقنية FUE اليدوية أو تقنية Micromotor FUE في الشقوق التي يتم إجراؤها باستخدام طريقة الشق الجانبي ، دون إتلاف الطعوم بأدوات فنية خاصة.
          `,
        ],
      },
      sedasyon: {
        title: 'زراعة الشعر بالتخدير',
        paragraph: [
          `إنها طريقة تخدير تم تطويرها للتأكد من أن عملية زراعة شعر المريض غير مؤلمة.
          `,
          `يتم إجراء التخدير من قبل طبيب التخدير.
          `,
          `بعد تطبيق التخدير يكون المريض واعياَ ويمكن التحدث مع المريض.
          `,
          `يمكن إجراء زراعة الشعر المهدئ للأشخاص الذين يعانون من مشاكل خاصة و نوبات الهلع ، وما شابه ذلك ، ممن هم يخافون من عملية الحقن.
          `,
        ],
      },
      implantasyonDHI: {
        title: 'زراعة الشعر بتقنية DHI',
        paragraph: [
          `DHI هي إحدى التقنيات المطبقة في زراعة بصيلات الشعر المأخوذة من المنطقة المانحة إلى المنطقة المراد زراعتها.
          `,
          `يتم نقل بصيلات الشعر المأخوذة بطريقة Fue إلى المنطقة باستخدام أقلام خاصة تسمى Choi Implanter.
          `,
          `في تقنية DHI ، يمكن وضع بصيلات الشعر في عملية واحدة (شق ووضع بصيلات الشعر).
          `,
          `يتم وضع بصيلات الشعر التي تم جمعها باستخدام قلم Choi ( وهو قلم طبي خاص )، واحدًا تلو الآخر بعناية وحذر في القلم.
          `,
          `بعد تحديد الزاوية الأنسب للزراعة وتحديد الاتجاه الصحيح ، يتم نقل بصيلات الشعر الموضوعة إلى المنطقة المراد زراعتها.
          `,
          `يتم إجراء التلقيم بواسطة ميزة دفع الإبرة.
          `,
          `يختلف عدد الأقلام المستخدمة في العملية وعدد رؤوس الأقلام وفقًا لسُمك بصيلة الشعر وبنية الشعر للشخص.
          .`,
        ],
      },
    },
    OnlineReservation: {
      title: 'تواصل معنا',
      subInfo: `نقدم خدمات الفحص والاستشارة المجانية للمرضى من قبل فريق الخبراء لدينا والمترجمين في مجال جراحة الشعر واللحية والشارب والحواجب. يرجى ملء النموذج أدناه للاستشارة الطبية .					`,
      formTitle: 'نموذج الاستشارة',
      list: [
        `* في قسم إضافة الصورة أدناه ، أضف صور المنطقة المانحة (المنطقة بين الأذنين) والمنطقة التي تريد زرعها (يجب أن تكون الصور واضحة وذات جودة عالية) كما هو موضح في الأمثلة.`,
        `* لكي نتمكن من تقديم خدمات استشارية فعالة لعملائنا ، قم بتعبئة الأسئلة في الأقسام أدناه بشكل صحيح.`,
      ],
      labels: {
        name: 'الاسم و الكنية',
        birthDate: 'تاريخ الميلاد',
        country: 'البلد ',
        phone: 'الهاتف',
        email: 'الايميل الالكتروني',
        job: 'المهنة',
        reference: 'كيف وصلت إلينا؟',
        patientsAdvice: 'توصية من مريض آخر',
        socialMedia: 'تواصل الإجتماعي',
        forum: 'نموذج',
        magazine: 'المجلة / جريدة',
        internet: 'الانترنت',
        other: 'اخرى',
        transplantTitle: 'ماذا تريد أن تزرع؟',
        hair: 'زراعة شعر',
        beard: 'زراعة اللحية',
        mustache: 'زراحة الشارب',
        eyebrow: 'زراعة الحاجب',
        informTransplantTitle: `أخبرنا المناطق التي تريد أن تتم تغطيتها بزراعة الشعر. ما هي توقعاتك لنتيجةالزراعة؟`,
        fueTitle: 'ما هي طريقة FUE المفضلة لديك؟',
        manuelFue: 'FUE اليدوي',
        micromotorFue: 'Micromotor FUE',
        micromotorShaved: 'زراعة مع حلاقة الشعر',
        micromotorUnShaved: 'زراعة بدون حلاقة الشعر',
        shavedInfo: `يفضل زراعة الشعر بدون حلاقة في الحالات التي تكون فيها الفراغات صغيرة ويمكن سد الفراغ بـ  3000 بصيلة أو أقل.`,
        transplantTypeTitle: `ما هو تفضيل نوع الزرع؟`,
        leteral: 'زراعة الشعر بالشق الجانبي',
        dhi: 'DHİ زراعة الشعر بـ',
        sedasyon: 'زراعة الشعر بالتخدير',
        hairLossMedicine: `هل هناك أي أدوية تستخدمها لمنع تساقط الشعر؟ إذا كانت الإجابة بنعم ، أبلغ عن الدواء الذي تستخدمه.					`,
        transplantBefore: `هل زرعت من قبل؟ إذا كانت إجابتك بنعم ، فيرجى إخبارنا بأفكارك حول العيادة والطبيب والتقنية المستخدمة وعدد البصيلات المزروعة ونتائج الزراعة.`,
        hadAnyIllness: `هل أصبت بأي مرض من قبل؟`,
        none: 'لا ',
        verem: 'السل الرؤوي ',
        tansiyon: 'مشاكل في الضغط ',
        heart: 'أمراض القلب',
        seker: 'مرض السكر',
        eklem: 'روماتيزم المفاصل ',
        epilepsi: 'أمراض الصرع',
        romatizma: 'الحرارة الروماتزمية ',
        zuhrevi: 'الزهري',
        guatr: 'امراض الغدة ',
        blood: 'الالتهاب الكبدي B أو C',
        bobrek: 'مشاكل الكلى و الكبد',
        astim: 'ضيق التنفس / الربو ',
        saman: 'حمى الكلأ',
        sarilik: 'الصفرة ',
        akciger: 'أمراض الجهاز التنفسي ',
        aids: 'نقص المناعة المكتسبة / الايدز',
        treatmentTitle: 'هل تتلقى حاليًا أي علاج أو تستخدم الدواء؟',
        alergicTo: 'هل هناك أي أدوية لديك حساسية منها؟ رجاء حددها',
        antibiyotik: 'حساسية المضاد الحيوي',
        painkiller: 'حساسية مسكنات الالم ',
        anestezik: 'حساسية مواد و أدوية التخدير ',
        localTitle: 'هل أجريت لك عملية تخدير موضعي من قبل؟',
        yes: 'نعم',
        no: 'لا',
        yesBut: 'إذا كانت الإجابة بنعم ، معلومات عن العملية',
        skinProblem: 'هل تعاني من أي مشاكل جلدية؟',
        dermatit: 'التهاب الجلد (إكزيما)',
        dermatitQuote: '',
        desquamasyon: 'التقشر (قشرة الرأس ، تقشير)',
        desquamasyonQuote: '',
        folikulit: 'التهاب الجريبات (التهاب بصيلات الشعر)',
        folikulitQuote: '',
        hipertrofik: 'تضخم ',
        keloid: 'جدرة',
        photoAddTitle: 'قسم إضافة الصور-',
        send: 'ارسال',
      },
    },
    OperasyonSonrasi: {
      title1: 'الأمور التي يجب مراعاتها بعد العملية',
      title2: '',
      title3: '',
      title4: '',
      list1: [
        `استخدم الأدوية التي يصفها الطبيب بانتظام بما يتماشى مع توصيات طبيبك (المضادات الحيوية ومسكنات الآلام .)`,
        `يجب أن تنام على ظهرك باستخدام وسادة الرقبة لمدة 5 (خمسة) أيام بعد العملية. (قد يؤدي الاستدارة إلى اليمين أو اليسار أو الاستلقاء على الوجه إلى إتلاف منطقة الزراعة.)`,
        `تجنب الاتصال الجنسي لمدة 5 (خمسة) أيام بعد العملية.`,
        `لا تستخدم الكحول أو السجائر لمدة أسبوع (واحد).`,
        `حماية منطقة الزراعة من قطرات المطر والشمس لمدة 10 (عشرة) أيام.`,
      ],
      list2: [
        `يحظر ارتداء القبعة او الضمادة للرأس (يمكن استخدام القبعة التي توفرها عيادتنا)  لمدة 10 (عشرة) أيام.`,
        `لمدة 10 (عشرة) أيام ، يفضل ارتداء الملابس ذات السحابات أو الأزرار ، ويجب عدم ارتداء الملابس التي تمر عبر الرأس.`,
        `يمنع ممارسة الرياضة والسباحة ومقصورة التشمس الاصطناعي والساونا والحمام التركي والجاكوزي وحمامات الشمس لمدة شهر واحد.`,
        `يجب توخي الحذر بعد العملية ، مع الحرص على عدم إتلاف منطقة الزراعة لمدة 6 (ستة) أشهر ، خاصة في العشرة (عشرة) أيام الأولى.`,
        `لا تصبغ شعرك لمدة 6 (ستة) أشهر ، ولا تستخدم الجل أو البخاخ ، ويجب قص الشعر بالمقص. يجب عدم استخدام  ماكينة الحلاقة لمدة 6 (ستة) أشهر.`,
        `يوصى باستخدام Propecia لمدة 2 (سنتين). يوصى بالاستخدام المستمر للحفاظ على تأثيره. لا يقوي Propecia شعرك الحالي ويمنعه من التساقط فحسب ، بل يعمل أيضًا على تكثيف شعرك الحالي والمزروع.`,
      ],
      title5: 'معلومات المريض وعملية الغسيل بعد العملية',
      list3: [
        `لا تقلق إذا كان لديك وذمة / تورم في وجهك ، رغم أنه نادر جدًا. ستختفي الوذمة / التورم في غضون 3 (ثلاثة) أيام. لمنع الوذمة / التورم ، ضع هلام الثلج الذي تعطيه لك عيادتنا بانتظام واحرص على عدم إمالة رأسك للأمام.`,
        `في حالة حدوث حكة و / أو ظهور حبوب في منطقة الزراعة ، توجه إلى عيادتنا.`,
      ],
      list4: [
        `قم بغسيل الرأس بانتظام لمدة 10 (عشرة) أيام بعد العملية وتنظيف القشور المتكونة في منطقة الزراعة في اليوم العاشر (العاشر) كما أوضحت لك عيادتنا.`,
      ],
      title6: 'يجب أن يتم الغسيل كما هو موضح أدناه.',
      list5: [
        `أولا وقبل كل شيء ، ضع المستحضر الذي أوصى به مركزنا على منطقة الزراعة وانتظر لمدة 15 دقيقة.`,
        `نظف المستحضر الذي وضعته قبل 15 دقيقة بلمسات خفيفة بالماء الدافئ بدون ضغط. `,
        `لا تقم بحركات قوية كالحَك ذهابًا وإيابًا في منطقة الزراعة.`,
      ],
      list6: [
        `بعد فرك الشامبو الموصى به من قبل مركزنا ، اجعله رغوة بين يديك ، ضعه على منطقة الزرع والمنطقة المانحة بلمسات خفيفة وشطفه بالماء الدافئ بدون ضغط لإزالة الرغوة. كرر عملية الغسل بالشامبو والشطف مرتين.`,
        `يجب تجفيف منطقة الزراعة بلمسات خفيفة ناعمة بمنشفة ورقية.`,
        `تأكد من استخدام الشامبو الذي تم إعطاؤه لك ، خاصة في العشرة (عشرة) أيام الأولى. بعد 10 (عشرة) أيام ، أي بعد انتهاء الشامبو الذي أوصى به مركزنا يمكنك استخدام أي شامبو تريده.`,
      ],
    },
  },
};

export const getLanguageData = page => {
  let currentLanguage = localStorage.getItem('language');

  return LanguageData[currentLanguage][page];
};
