import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { Provider } from 'react-redux';
// import configureStore from './configureStore';
import './assets/style/variables.module.scss';

// export const store = configureStore();

// export const state = store.getState();

ReactDOM.render(<App />, document.getElementById('root'));
