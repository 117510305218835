import { Header, FooterForm } from '../../components';
import style from './fuesacekimi.module.scss';
import { useEffect, useState, useCallback } from 'react';
import { getLanguageData } from '../../data/Language';
import { useLocation } from 'react-router-dom';

import DHIImage from '../../assets/img/fue/dhi_choi.jpg';
import SafirImage from '../../assets/img/fue/implantasyon-safir-fue.png';
import MicromotorImage from '../../assets/img/fue/micromotor.jpg';
import ManuelImage from '../../assets/img/fue/manuel.png';
import FueHeadImage from '../../assets/img/fue/fue-head-image.jpg';
import InzisyonImage from '../../assets/img/fue/insizyon-image.jpeg';
import SedasyonImage from '../../assets/img/fue/sedasyon-image.jpg';
import LongUnshavedImage from '../../assets/img/fue/long-unshaved-manuel-fue.jpg';
import LeteralSlitImage from '../../assets/img/fue/leteral-slit-image.jpg';

const FueSacEkimi = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('Fue'));
  }, []);

  const location = useLocation();
  const currentHash = location.hash.split('#')[1];

  const manuelRef = useCallback(
    node => {
      if (node !== null && currentHash === 'manuel') {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [currentHash]
  );

  const micromotorRef = useCallback(
    node => {
      if (node !== null && currentHash === 'micromotor') {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [currentHash]
  );

  const unshavedRef = useCallback(
    node => {
      if (node !== null && currentHash === 'unshaved') {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [currentHash]
  );

  const leteralRef = useCallback(
    node => {
      if (node !== null && currentHash === 'leteral') {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [currentHash]
  );

  const dhiRef = useCallback(
    node => {
      if (node !== null && currentHash === 'dhi') {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [currentHash]
  );

  const sedasyonRef = useCallback(
    node => {
      if (node !== null && currentHash === 'sedasyon') {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [currentHash]
  );

  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.fueSacEkimi}>
            <div className={style.wrapper}>
              <div className={style.card}>
                <h1>
                  {data.title1} <br />
                  <span>
                    {data.title2}
                    <br /> {data.title3}
                  </span>
                </h1>
                <img src={FueHeadImage} alt="fue-head" />
                <p>
                  {data.top.paragraph1}
                  <br />
                  <div className={style.space} />
                  {data.top.paragraph2}
                  <br />
                  <div className={style.space} />
                  {data.top.paragraph3}
                  <br />
                  <div className={style.space} />
                  {data.top.paragraph4}
                </p>
              </div>
              <div className={style.listCardWrapper}>
                <h3>{data.benefitTitle}</h3>
                <div className={style.listCard}>
                  <ul>
                    {data.benefits.left.map(item => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                  <ul>
                    {data.benefits.right.map(item => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <h4 className={style.subTitle}>
                {data.info1}
                <br /> {data.info2}
              </h4>
              <div ref={manuelRef} className={style.content}>
                <div className={style.column}>
                  <h3>{data.manuel.title}</h3>
                  <ul>
                    {data.manuel.list.map(item => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                  <p>
                    {data.manuel.paragraph.map((item, index) => {
                      return (
                        <>
                          {item}
                          {index > 0 ||
                          data.manuel.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
                <div className={`${style.column} `}>
                  <img
                    className={style.imageFirst}
                    src={ManuelImage}
                    alt="head"
                  />
                </div>
              </div>
              <div
                ref={micromotorRef}
                className={`${style.content} ${style.contentReverse}`}
              >
                <div className={style.column}>
                  <h3>{data.micromotor.title}</h3>
                  <ul>
                    {data.micromotor.list.map(item => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                  <p>
                    {data.micromotor.paragraph.map((item, index) => {
                      return (
                        <>
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
                <div className={style.column}>
                  <img src={MicromotorImage} alt="head" />
                </div>
              </div>
              <div
                ref={unshavedRef}
                className={`${style.content} ${style.margin}`}
              >
                <div className={style.column}>
                  <h3>{data.shaved.title}</h3>
                  <p>
                    {data.shaved.paragraph.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? <br /> : null}
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
                <div className={style.column}>
                  <img src={LongUnshavedImage} alt="head" />
                </div>
              </div>
              <div
                className={`${style.content} ${style.contentReverse} ${style.margin}`}
              >
                <div className={style.column}>
                  <h3>{data.insizyon.title}</h3>
                  <p>
                    {data.insizyon.paragraph.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? <br /> : null}
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
                <div className={style.column}>
                  <img src={InzisyonImage} alt="head" />
                </div>
              </div>
              <div
                ref={leteralRef}
                className={`${style.content} ${style.contentReverse} ${style.margin} ${style.mobile}`}
              >
                <div className={style.column}>
                  <img src={LeteralSlitImage} alt="head" />
                </div>
                <div className={style.column}>
                  <h3>{data.leteral.title}</h3>
                  <p>
                    {data.leteral.paragraph.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? <br /> : null}
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                  <h5>{data.leteral.title};</h5>
                  <ul>
                    {data.leteral.list.map(item => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <div
                className={`${style.content} ${style.margin} ${style.mobile}`}
              >
                <div className={style.column}>
                  <img src={SafirImage} alt="head" />
                </div>
                <div className={style.column}>
                  <h3>{data.implantasyon.title}</h3>
                  <p>
                    {data.implantasyon.paragraph.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? <br /> : null}
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
              <div
                ref={sedasyonRef}
                className={`${style.content} ${style.contentReverse} ${style.margin} ${style.mobile}`}
              >
                <div className={style.column}>
                  <img src={SedasyonImage} alt="head" />
                </div>
                <div className={style.column}>
                  <h3>{data.sedasyon.title}</h3>
                  <p>
                    {data.sedasyon.paragraph.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? <br /> : null}
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
              <div
                ref={dhiRef}
                className={`${style.content} ${style.margin} ${style.mobile}`}
              >
                <div className={style.column}>
                  <img
                    className={style.imageSecond}
                    src={DHIImage}
                    alt="head"
                  />
                </div>
                <div className={style.column}>
                  <h3>{data.implantasyonDHI.title}</h3>
                  <p>
                    {data.implantasyonDHI.paragraph.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? <br /> : null}
                          {item}
                          {index > 0 ||
                          data.micromotor.paragraph.length === index + 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default FueSacEkimi;
