import {
  UnderConstraction,
  Home,
  About,
  Clinic,
  KasEkimi,
  SakalEkimi,
  SacDokulmesiNedenleri,
  OperasyonSonrasiTalimatlar,
  SacDokulmesiTedavileri,
  FueSacEkimi,
  OnlineReservation,
  Contact,
} from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '/',
    component: <Home />,
    exact: true,
  },
  {
    title: 'S&S Med Hakkında',
    path: '/hakkimizda',
    component: <About />,
    exact: true,
  },
  {
    title: 'Klinik',
    path: '/klinik',
    component: <Clinic />,
    exact: true,
  },
  {
    title: 'İletişim',
    path: '/iletisim',
    component: <Contact />,
    exact: true,
  },
  {
    title: 'Kurumsal',
    path: '/kurumsal',
    component: <About />,
    exact: true,
  },
  {
    title: 'Kaş Ekimi',
    path: '/kas-ekimi',
    component: <KasEkimi />,
    exact: true,
  },
  {
    title: 'Sakal Ekimi',
    path: '/sakal-biyik-ekimi',
    component: <SakalEkimi />,
    exact: true,
  },
  {
    title: 'Saç Dökülmesi Nedenleri',
    path: '/sac-dokulmesi-nedenleri',
    component: <SacDokulmesiNedenleri />,
    exact: true,
  },
  {
    title: 'Saç Dökülmesi Tedavileri',
    path: '/tibbi-tedavi',
    component: <SacDokulmesiTedavileri />,
    exact: true,
  },
  {
    title: 'Operasyon Sonrası Talimatlar',
    path: '/operasyon-sonrasi-talimatlar',
    component: <OperasyonSonrasiTalimatlar />,
    exact: true,
  },
  {
    title: 'Fue Saç Ekimi',
    path: '/fue-sac-ekimi',
    component: <FueSacEkimi />,
    exact: true,
  },
  {
    title: 'Online Reservation',
    path: '/online-rezervasyon',
    component: <OnlineReservation />,
    exact: true,
  },

  {
    title: 'UnderConstraction',
    path: '*',
    component: <UnderConstraction />,
  },
];
