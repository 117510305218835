import { Header, Popup } from '../../components';
import style from './onlinereservation.module.scss';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { PlusIcon } from '../../assets/icons';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BackendUrl } from '../../constants/ApiConfig';
import { useNavigate } from 'react-router-dom';
import { getLanguageData } from '../../data/Language';

import Ornek1 from '../../assets/img/reservation/ornek_1.jpeg';
import Ornek2 from '../../assets/img/reservation/ornek_2.jpg';
import Ornek3 from '../../assets/img/reservation/ornek_3.jpg';
import Ornek4 from '../../assets/img/reservation/ornek_4.jpeg';
import Ornek5 from '../../assets/img/reservation/ornek_5.jpeg';
import Ornek6 from '../../assets/img/reservation/ornek_6.jpg';

import ReservationHead from '../../assets/img/reservation/reservation-head.png';

const lang = localStorage.getItem('language');

const SignupSchema = Yup.object().shape({
  nameSurname: Yup.string()
    .min(
      3,
      lang === 'TR'
        ? 'Kısa isim'
        : lang === 'ARB'
        ? `اسم قصير`
        : lang === 'EN'
        ? 'Short name'
        : 'Nombre corto'
    )
    .max(
      100,
      lang === 'TR'
        ? 'Uzun isim'
        : lang === 'ARB'
        ? `اسم طويل`
        : lang === 'EN'
        ? 'Long name'
        : 'Nombre largo'
    )
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    ),
  birthDate: Yup.string().required(
    lang === 'TR'
      ? 'Zorunlu alan'
      : lang === 'ARB'
      ? `يتطلب حقلا`
      : lang === 'EN'
      ? 'Required field'
      : 'Campo requerido'
  ),
  country: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      3,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  phone: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      3,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  email: Yup.string()
    .email('Doğru email giriniz')
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    ),
  job: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      3,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  reference: Yup.array().min(
    1,
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  transplant: Yup.array().min(
    1,
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  expectationResult: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      10,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  fueType: Yup.string().required(
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  transplantType: Yup.string().required(
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  hadAnyIllness: Yup.array().min(
    1,
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  medicationsAllergicTo: Yup.array().min(
    1,
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  localAnesthesia: Yup.string().required(
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  skinProblems: Yup.array().min(
    1,
    lang === 'TR'
      ? 'Seçim yapınız'
      : lang === 'ARB'
      ? `أختار`
      : lang === 'EN'
      ? 'Please select'
      : 'Tomar una decisión'
  ),
  // terms: Yup.boolean()
  //   .required('Lütfen kullanım koşullarını kabul edin.')
  //   .oneOf([true], 'Lütfen kullanım koşullarını kabul edin.'),
});

const ReservationForm = ({
  click,
  formValue,
  data,
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo1Change,
  photo2Change,
  photo3Change,
  photo4Change,
  photo5Change,
  photo6Change,
}) => {
  return (
    <Formik
      initialValues={{
        nameSurname: '',
        birthDate: '',
        country: '',
        phone: '',
        email: '',
        job: '',
        reference: [],
        referenceOther: '',
        transplant: [],
        expectationResult: '',
        fueType: '',
        transplantType: '',
        hairLossMedicine: '',
        plantedBefore: '',
        hadAnyIllness: [],
        hadAnyIllnessOther: '',
        receivingAnyTreatment: '',
        medicationsAllergicTo: [],
        medicationsAllergicToOther: '',
        localAnesthesia: '',
        localAnesthesiaYesBut: '',
        skinProblems: [],
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        formValue(values);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form
          className={`${style.form} ${
            localStorage.getItem('language') === 'ARB' ? style.arb : null
          }`}
        >
          <div className={style.row}>
            <label className={style.input} htmlFor={'nameSurname'}>
              <h5>{data.name}</h5>
              <Field
                id={'nameSurname'}
                type="text"
                name={'nameSurname'}
                value={values.nameSurname}
              />
              {errors.nameSurname ? (
                <div className={style.error}>{errors.nameSurname}</div>
              ) : null}
            </label>

            <label className={style.input} htmlFor={'birthDate'}>
              <h5>{data.birthDate}</h5>
              <Field
                id={'birthDate'}
                type="date"
                name={'birthDate'}
                value={values.birthDate}
              />
              {errors.birthDate ? (
                <div className={style.error}>{errors.birthDate}</div>
              ) : null}
            </label>

            <label className={style.input} htmlFor={'country'}>
              <h5>{data.country}</h5>
              <Field
                id={'country'}
                type="text"
                name={'country'}
                value={values.country}
              />
              {errors.country ? (
                <div className={style.error}>{errors.country}</div>
              ) : null}
            </label>
          </div>

          <div className={style.row}>
            <label className={style.input} htmlFor={'phone'}>
              <h5>{data.phone}</h5>
              <Field
                id={'phone'}
                type="tel"
                name={'phone'}
                value={values.phone}
              />
              {errors.phone ? (
                <div className={style.error}>{errors.phone}</div>
              ) : null}
            </label>
            <label className={style.input} htmlFor={'email'}>
              <h5>{data.email}</h5>
              <Field
                id={'email'}
                type="email"
                name={'email'}
                value={values.email}
              />
              {errors.email ? (
                <div className={style.error}>{errors.email}</div>
              ) : null}
            </label>
            <label className={style.input} htmlFor={'job'}>
              <h5>{data.job}</h5>
              <Field id={'job'} type="text" name={'job'} value={values.job} />
              {errors.job ? (
                <div className={style.error}>{errors.job}</div>
              ) : null}
            </label>
          </div>

          <div className={style.radioColumn}>
            <h5>{data.reference}</h5>
            {errors.reference ? (
              <div className={style.error}>{errors.reference}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.reference.includes('Hastanızın Tavsiyesi')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="reference"
                    value="Hastanızın Tavsiyesi"
                  />
                  {data.patientsAdvice}
                </label>
                <label
                  className={`${style.radio} ${
                    values.reference.includes('Sosyal Medya')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="reference"
                    value="Sosyal Medya"
                  />
                  {data.socialMedia}
                </label>
                <label
                  className={`${style.radio} ${
                    values.reference.includes('Forum')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="reference" value="Forum" />
                  {data.forum}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.reference.includes('Dergi/Gazete')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="reference"
                    value="Dergi/Gazete"
                  />
                  {data.magazine}
                </label>
                <label
                  className={`${style.radio} ${
                    values.reference.includes('İnternet')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="reference" value="İnternet" />
                  {data.internet}
                </label>
                <label
                  className={`${style.radio} ${
                    values.reference.includes('other')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="reference" value="other" />
                  {data.other}
                </label>
              </div>
            </div>
          </div>
          {values.reference.includes('other') ? (
            <div className={style.textareaColumn}>
              <label className={style.textArea}>
                <Field
                  as="textarea"
                  id={'referenceOther'}
                  name={'referenceOther'}
                  value={values.referenceOther}
                />
              </label>
            </div>
          ) : null}
          <div className={style.radioColumn}>
            <h5>{data.transplantTitle}</h5>
            {errors.transplant ? (
              <div className={style.error}>{errors.transplant}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.transplant.includes('Saç Ekimi')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="transplant" value="Saç Ekimi" />
                  {data.hair}
                </label>
                <label
                  className={`${style.radio} ${
                    values.transplant.includes('Sakal Ekimi')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="transplant"
                    value="Sakal Ekimi"
                  />
                  {data.beard}
                </label>
                <label
                  className={`${style.radio} ${
                    values.transplant.includes('Bıyık Ekimi')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="transplant"
                    value="Bıyık Ekimi"
                  />
                  {data.mustache}
                </label>
                <label
                  className={`${style.radio} ${
                    values.transplant.includes('Kaş Ekimi')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="transplant" value="Kaş Ekimi" />
                  {data.eyebrow}
                </label>
              </div>
            </div>
          </div>

          <div className={style.textareaColumn}>
            <h5>{data.informTransplantTitle}</h5>
            <label className={style.textArea}>
              <Field
                as="textarea"
                id={'expectationResult'}
                name={'expectationResult'}
                value={values.expectationResult}
              />
            </label>
            {errors.expectationResult ? (
              <div className={style.error}>{errors.expectationResult}</div>
            ) : null}
          </div>

          <div className={style.radioColumn}>
            <h5> {data.fueTitle}</h5>
            {errors.fueType ? (
              <div className={style.error}>{errors.fueType}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  style={{
                    width: '50%',
                  }}
                  className={`${style.radio} ${
                    values.fueType === 'Manuel FUE' ? style.radioSelected : null
                  }`}
                >
                  <Field type="radio" name="fueType" value="Manuel FUE" />
                  {data.manuelFue}
                </label>
                <label
                  style={{
                    width: '50%',
                  }}
                  className={`${style.radio} ${
                    values.fueType.includes(
                      'Micromotor FUE',
                      'Micromotor FUE Traşsız',
                      'Micromotor FUE Traşlı'
                    )
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="radio" name="fueType" value="Micromotor FUE" />
                  {data.micromotorFue}
                </label>
              </div>
              {values.fueType.includes(
                'Manuel FUE',
                'Manuel FUE Traşsız',
                'Manuel FUE Traşlı'
              ) ? (
                <div className={`${style.row} ${style.rowEnd}`}>
                  <label
                    style={{
                      width: '50%',
                    }}
                    className={`${style.radio} ${
                      values.fueType === 'Manuel FUE Traşlı'
                        ? style.radioSelected
                        : null
                    }`}
                  >
                    <Field
                      type="radio"
                      name="fueType"
                      value="Manuel FUE Traşlı"
                    />
                    {data.micromotorShaved}
                  </label>
                  <label
                    style={{
                      width: '50%',
                    }}
                    className={`${style.radio} ${
                      values.fueType === 'Manuel FUE Traşsız'
                        ? style.radioSelected
                        : null
                    }`}
                  >
                    <Field
                      type="radio"
                      name="fueType"
                      value="Manuel FUE Traşsız"
                    />
                    {data.micromotorUnShaved}
                  </label>
                </div>
              ) : null}
            </div>
          </div>

          {values.fueType.includes(
            'Manuel FUE',
            'Manuel FUE Traşsız',
            'Manuel FUE Traşlı'
          ) ? (
            <div className={style.info}>{data.shavedInfo}</div>
          ) : null}

          <div className={style.radioColumn}>
            <h5> {data.transplantTypeTitle}</h5>
            {errors.transplantType ? (
              <div className={style.error}>{errors.transplantType}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.transplantType === 'Lateral Slit Saç Ekimi'
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="radio"
                    name="transplantType"
                    value="Lateral Slit Saç Ekimi"
                  />
                  {data.leteral}
                </label>
                <label
                  className={`${style.radio} ${
                    values.transplantType === 'DHİ Saç Ekimi'
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="radio"
                    name="transplantType"
                    value="DHİ Saç Ekimi"
                  />
                  {data.dhi}
                </label>
                <label
                  className={`${style.radio} ${
                    values.transplantType === 'Sedasyonlu Saç Ekimi'
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="radio"
                    name="transplantType"
                    value="Sedasyonlu Saç Ekimi"
                  />
                  {data.sedasyon}
                </label>
              </div>
            </div>
          </div>

          <div className={style.textareaColumn}>
            <h5>{data.hairLossMedicine}</h5>
            <label className={style.textArea}>
              <Field
                as="textarea"
                id={'hairLossMedicine'}
                name={'hairLossMedicine'}
                value={values.hairLossMedicine}
              />
            </label>
          </div>

          <div className={style.textareaColumn}>
            <h5>{data.transplantBefore}</h5>
            <label className={style.textArea}>
              <Field
                as="textarea"
                id={'plantedBefore'}
                name={'plantedBefore'}
                value={values.plantedBefore}
              />
            </label>
          </div>

          <div className={style.radioColumn}>
            <h5> {data.hadAnyIllness} </h5>
            {errors.hadAnyIllness ? (
              <div className={style.error}>{errors.hadAnyIllness}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness[0] === 'Yok' &&
                    values.hadAnyIllness.length === 1
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    onChange={() => {
                      setFieldValue('hadAnyIllness', ['Yok']);
                    }}
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Yok"
                  />
                  {data.none}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Verem')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="hadAnyIllness" value="Verem" />
                  {data.verem}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Tansiyon')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Tansiyon"
                  />
                  {data.tansiyon}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Kalp Hastalıkları')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Kalp Hastalıkları"
                  />
                  {data.heart}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Şeker Hastalıkları')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Şeker Hastalıkları"
                  />
                  {data.seker}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Eklem Romatizması')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Eklem Romatizması"
                  />
                  {data.eklem}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Epilepsi (Sara)')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Epilepsi (Sara)"
                  />
                  {data.epilepsi}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Ateşli Romatizma')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Ateşli Romatizma"
                  />
                  {data.romatizma}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Zührevi Hastalık')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Zührevi Hastalık"
                  />
                  {data.zuhrevi}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Guatr (Troid)')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Guatr (Troid)"
                  />
                  {data.guatr}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes(
                      'Kan Hastalıkları (Hepatit B - C)'
                    )
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Kan Hastalıkları (Hepatit B - C)"
                  />
                  {data.blood}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes(
                      'Böbrek, Karaciğer Bozuklukları'
                    )
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Böbrek, Karaciğer Bozuklukları"
                  />
                  {data.bobrek}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Astım')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="hadAnyIllness" value="Astım" />
                  {data.astim}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Saman Nezlesi')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Saman Nezlesi"
                  />
                  {data.saman}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Sarılık')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="hadAnyIllness" value="Sarılık" />
                  {data.sarilik}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('Akciğer Hastalıkları')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="hadAnyIllness"
                    value="Akciğer Hastalıkları"
                  />
                  {data.akciger}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('AIDS')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="hadAnyIllness" value="AIDS" />
                  {data.aids}
                </label>
                <label
                  className={`${style.radio} ${
                    values.hadAnyIllness.includes('other')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="hadAnyIllness" value="other" />
                  {data.other}
                </label>
              </div>
            </div>
          </div>

          {values.hadAnyIllness.includes('other') ? (
            <div className={style.textareaColumn}>
              <label className={style.textArea}>
                <Field
                  as="textarea"
                  id={'hadAnyIllnessOther'}
                  name={'hadAnyIllnessOther'}
                  value={values.hadAnyIllnessOther}
                />
              </label>
            </div>
          ) : null}

          <div className={style.textareaColumn}>
            <h5>{data.treatmentTitle}</h5>
            <label className={style.textArea}>
              <Field
                as="textarea"
                id={'receivingAnyTreatment'}
                name={'receivingAnyTreatment'}
                value={values.receivingAnyTreatment}
              />
            </label>
          </div>

          <div className={style.radioColumn}>
            <h5> {data.alergicTo}</h5>
            {errors.medicationsAllergicTo ? (
              <div className={style.error}>{errors.medicationsAllergicTo}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.medicationsAllergicTo.includes(
                      'Antibiyotik Alerjisi'
                    )
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="medicationsAllergicTo"
                    value="Antibiyotik Alerjisi"
                  />
                  {data.antibiyotik}
                </label>
                <label
                  className={`${style.radio} ${
                    values.medicationsAllergicTo.includes(
                      'Ağrı Kesici Alerjisi'
                    )
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="medicationsAllergicTo"
                    value="Ağrı Kesici Alerjisi"
                  />
                  {data.painkiller}
                </label>
                <label
                  className={`${style.radio} ${
                    values.medicationsAllergicTo.includes(
                      'Anestezik İlaçlar Alerjisi'
                    )
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="medicationsAllergicTo"
                    value="Anestezik İlaçlar Alerjisi"
                  />
                  {data.anestezik}
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.medicationsAllergicTo[0] === 'Yok' &&
                    values.medicationsAllergicTo.length === 1
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="medicationsAllergicTo"
                    value="Yok"
                    onChange={() => {
                      setFieldValue('medicationsAllergicTo', ['Yok']);
                    }}
                  />
                  {data.none}
                </label>
                <label
                  className={`${style.radio} ${
                    values.medicationsAllergicTo.includes('other')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="medicationsAllergicTo"
                    value="other"
                  />
                  {data.other}
                </label>
                <label
                  style={{
                    opacity: 0,
                  }}
                  className={`${style.radio}`}
                ></label>
              </div>
            </div>
          </div>

          {values.medicationsAllergicTo.includes('other') ? (
            <div className={style.textareaColumn}>
              <label className={style.textArea}>
                <Field
                  as="textarea"
                  id={'medicationsAllergicToOther'}
                  name={'medicationsAllergicToOther'}
                  value={values.medicationsAllergicToOther}
                />
              </label>
            </div>
          ) : null}

          <div className={style.radioColumn}>
            <h5>{data.localTitle}</h5>
            {errors.localAnesthesia ? (
              <div className={style.error}>{errors.localAnesthesia}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.localAnesthesia === 'Evet'
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="radio" name="localAnesthesia" value="Evet" />
                  {data.yes}
                </label>
                <label
                  className={`${style.radio} ${
                    values.localAnesthesia === 'Hayır'
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="radio" name="localAnesthesia" value="Hayır" />
                  {data.no}
                </label>
              </div>
            </div>
          </div>

          {values.localAnesthesia === 'Evet' ? (
            <div className={style.textareaColumn}>
              <h5>{data.yesBut}</h5>
              <label className={style.textArea}>
                <Field
                  as="textarea"
                  id={'localAnesthesiaYesBut'}
                  name={'localAnesthesiaYesBut'}
                  value={values.localAnesthesiaYesBut}
                />
              </label>
            </div>
          ) : null}

          <div className={style.radioColumn}>
            <h5> {data.skinProblem}</h5>
            {errors.skinProblems ? (
              <div className={style.error}>{errors.skinProblems}</div>
            ) : null}
            <div className={style.radioWrapper}>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.skinProblems[0] === 'Hayır' &&
                    values.skinProblems.length === 1
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    onChange={() => {
                      setFieldValue('skinProblems', ['Hayır']);
                    }}
                    type="radio"
                    name="skinProblems"
                    value="Hayır"
                  />
                  {data.no}
                </label>
                <label
                  className={`${style.radio} ${
                    values.skinProblems.includes('Dermatit')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="skinProblems" value="Dermatit" />
                  {data.dermatit} <span>{data.dermatitQuote}</span>
                </label>
                <label
                  className={`${style.radio} ${
                    values.skinProblems.includes('Desquamasyon')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="skinProblems"
                    value="Desquamasyon"
                  />
                  {data.desquamasyon} <span>{data.desquamasyonQuote}</span>
                </label>
              </div>
              <div className={style.row}>
                <label
                  className={`${style.radio} ${
                    values.skinProblems.includes('Folikülit')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="skinProblems"
                    value="Folikülit"
                  />
                  {data.folikulit} <span>{data.folikulitQuote}</span>
                </label>
                <label
                  className={`${style.radio} ${
                    values.skinProblems.includes('Hipertrofik')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field
                    type="checkbox"
                    name="skinProblems"
                    value="Hipertrofik"
                  />
                  {data.hipertrofik}
                </label>
                <label
                  className={`${style.radio} ${
                    values.skinProblems.includes('Keloid')
                      ? style.radioSelected
                      : null
                  }`}
                >
                  <Field type="checkbox" name="skinProblems" value="Keloid" />
                  {data.keloid}
                </label>
              </div>
            </div>
          </div>

          <div className={style.photoColumn}>
            <h5>{data.photoAddTitle} </h5>
            <div className={style.examplePhoto}>
              <img src={Ornek1} alt="ornek-1" />
              <img src={Ornek2} alt="ornek-2" />
              <img src={Ornek3} alt="ornek-3" />
            </div>

            <div className={style.photoWrapper}>
              <label
                className={`${style.photo} ${photo1 ? style.uploaded : null}`}
              >
                <input
                  onChange={e => photo1Change(e.target.files[0])}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                {photo1 ? (
                  <img src={URL.createObjectURL(photo1)} alt="patient-1" />
                ) : (
                  <PlusIcon />
                )}
              </label>
              <label
                className={`${style.photo} ${photo2 ? style.uploaded : null}`}
              >
                <input
                  onChange={e => photo2Change(e.target.files[0])}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                {photo2 ? (
                  <img src={URL.createObjectURL(photo2)} alt="patient-2" />
                ) : (
                  <PlusIcon />
                )}
              </label>
              <label
                className={`${style.photo} ${photo3 ? style.uploaded : null}`}
              >
                <input
                  onChange={e => photo3Change(e.target.files[0])}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                {photo3 ? (
                  <img src={URL.createObjectURL(photo3)} alt="patient-3" />
                ) : (
                  <PlusIcon />
                )}
              </label>
            </div>
            <div className={style.examplePhoto}>
              <img src={Ornek4} alt="ornek-4" />
              <img src={Ornek5} alt="ornek-5" />
              <img src={Ornek6} alt="ornek-6" />
            </div>
            <div className={style.photoWrapper}>
              <label
                className={`${style.photo} ${photo4 ? style.uploaded : null}`}
              >
                <input
                  onChange={e => photo4Change(e.target.files[0])}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                {photo4 ? (
                  <img src={URL.createObjectURL(photo4)} alt="patient-1" />
                ) : (
                  <PlusIcon />
                )}
              </label>
              <label
                className={`${style.photo} ${photo5 ? style.uploaded : null}`}
              >
                <input
                  onChange={e => photo5Change(e.target.files[0])}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                {photo5 ? (
                  <img src={URL.createObjectURL(photo5)} alt="patient-2" />
                ) : (
                  <PlusIcon />
                )}
              </label>
              <label
                className={`${style.photo} ${photo6 ? style.uploaded : null}`}
              >
                <input
                  onChange={e => photo6Change(e.target.files[0])}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                />
                {photo6 ? (
                  <img src={URL.createObjectURL(photo6)} alt="patient-3" />
                ) : (
                  <PlusIcon />
                )}
              </label>
            </div>
          </div>

          <div className={style.submitWrapper}>
            {/* <label className={style.checkbox}>
              <Field name="terms" type="checkbox" />
              <span>Koşulları kabul edin</span>
            </label>
            {errors.terms ? (
              <div className={style.error}>{errors.terms}</div>
            ) : null} */}
            <button onClick={click} className={style.submit} type="submit">
              {data.send}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const OnlineReservation = () => {
  const [isOpen, setOpen] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [photo1, setPhoto1] = useState();
  const [photo2, setPhoto2] = useState();
  const [photo3, setPhoto3] = useState();
  const [photo4, setPhoto4] = useState();
  const [photo5, setPhoto5] = useState();
  const [photo6, setPhoto6] = useState();

  useEffect(() => {
    setData(getLanguageData('OnlineReservation'));
  }, []);

  const sendOnlineReservation = async value => {
    let formData = await new FormData();
    let valueJSON = JSON.stringify(value);
    await formData.append('formValue', valueJSON);
    await formData.append('image', photo1);
    await formData.append('image', photo2);
    await formData.append('image', photo3);
    await formData.append('image', photo4);
    await formData.append('image', photo5);
    await formData.append('image', photo6);
    await axios
      .post(`${BackendUrl}/reservation/createReservation`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      })
      .then(res => {
        console.log(res.data);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {data ? (
        <>
          {isOpen ? (
            <Popup
              click={() => {
                setOpen(false);
                navigate('/');
              }}
              isLoad={isLoad}
            />
          ) : null}
          <Header />
          <div className={style.onlineReservation}>
            <div className={style.wrapper}>
              <div
                style={{
                  backgroundImage: `url(${ReservationHead})`,
                }}
                className={style.imageCard}
              >
                <h2>{data.title}</h2>
              </div>
              <div className={style.subInfo}>{data.subInfo}</div>
              <div className={style.infoCard}>
                <h2>{data.formTitle}</h2>
                <ul>
                  {data.list.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
              <div className={style.formWrapper}>
                <ReservationForm
                  photo1={photo1}
                  photo2={photo2}
                  photo3={photo3}
                  photo4={photo4}
                  photo5={photo5}
                  photo6={photo6}
                  photo1Change={e => setPhoto1(e)}
                  photo2Change={e => setPhoto2(e)}
                  photo3Change={e => setPhoto3(e)}
                  photo4Change={e => setPhoto4(e)}
                  photo5Change={e => setPhoto5(e)}
                  photo6Change={e => setPhoto6(e)}
                  data={data.labels}
                  formValue={e => {
                    console.log(e);
                    let newValues = {
                      ...e,
                      reference: e.referenceOther.length
                        ? [...e.reference, e.referenceOther]
                        : e.reference,
                      language: localStorage.getItem('language'),
                      hadAnyIllness:
                        e.hadAnyIllness.length > 1
                          ? e.hadAnyIllness.filter(i => i !== 'Yok')
                          : e.hadAnyIllness,
                      skinProblems:
                        e.skinProblems.length > 1
                          ? e.skinProblems.filter(i => i !== 'Hayır')
                          : e.skinProblems,
                      medicationsAllergicTo:
                        e.medicationsAllergicTo.length > 1
                          ? e.medicationsAllergicTo.filter(i => i !== 'Yok')
                          : e.medicationsAllergicTo,
                    };

                    let otherValues = {
                      ...newValues,
                      hadAnyIllness: newValues.hadAnyIllnessOther.length
                        ? [
                            ...newValues.hadAnyIllness,
                            newValues.hadAnyIllnessOther,
                          ]
                        : newValues.hadAnyIllness,
                      medicationsAllergicTo: newValues
                        .medicationsAllergicToOther.length
                        ? [
                            ...newValues.medicationsAllergicTo,
                            newValues.medicationsAllergicToOther,
                          ]
                        : newValues.medicationsAllergicTo,
                      localAnesthesia:
                        newValues.localAnesthesia === 'Evet'
                          ? newValues.localAnesthesiaYesBut
                          : newValues.localAnesthesia,
                    };

                    setOpen(true);
                    setLoad(true);
                    sendOnlineReservation(otherValues);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default OnlineReservation;
