import style from './kasekimi.module.scss';
import { FooterForm, Header } from '../../components';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';

import KasImage from '../../assets/img/kas_ekimi.png';

const KasEkimi = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('KasEkimi'));
  }, []);
  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.kasEkimi}>
            <div className={style.wrapper}>
              <div className={style.card}>
                <h1>{data.title}</h1>
                <img src={KasImage} alt="kas" />
                <p>
                  {data.paragraph1}
                  <br />
                  <div className={style.space} />
                  {data.paragraph2}
                  <br />
                  <div className={style.space} />
                  {data.paragraph3}
                </p>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default KasEkimi;
