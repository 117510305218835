import { Header, FooterForm } from '../../components';
import style from './sacdokulmesinedenleri.module.scss';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';
import SacDokulmesiHead from '../../assets/img/sac-dokulmesi-head.jpg';

const SacDokulmesiNedenleri = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('SacDokulmesiNedenleri'));
  }, []);

  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.sacDokulmesiNedenleri}>
            <div className={style.wrapper}>
              <div
                style={{
                  backgroundImage: `url(${SacDokulmesiHead})`,
                }}
                className={style.headCard}
              >
                <h1>
                  {data.title1} <br />
                  {data.title2}
                </h1>
              </div>
              <div className={style.paragraphWrapper}>
                <div className={style.paragraph}>
                  <div className={style.column}>
                    <p>
                      {data.paragraph1}
                      <br />
                      <br />
                    </p>
                    <h2>
                      {data.subTitle1Part1} <span>{data.subTitle1Part2}</span>
                    </h2>
                    <p>
                      {data.paragraph2}
                      <br />
                      <br />
                    </p>
                    <p>{data.paragraph3}</p>
                  </div>
                  <div className={style.column}>
                    <h2>{data.subTitle2}</h2>
                    <p>
                      {data.paragraph4}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph5}
                      <br />
                      <br />
                    </p>
                    <p>{data.paragraph6}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default SacDokulmesiNedenleri;
