import style from './home.module.scss';
import { FooterForm, Header } from '../../components';
import { getLanguageData } from '../../data/Language';
import { useEffect, useState } from 'react';
import HomeBackground from '../../assets/img/home-background.jpg';
import HomeBackgroundArb from '../../assets/img/home-background-arb.jpg';

const HeadInfo = ({ data }) => {
  const language = localStorage.getItem('language');
  return (
    <div
      style={{
        backgroundImage: `url(${
          language === 'ARB' ? HomeBackgroundArb : HomeBackground
        })`,
      }}
      className={style.headInfo}
    >
      <div className={style.column}>
        <h1>{data.HeadInfo.title}</h1>
        <p>
          {data.HeadInfo.paragraph1}
          <br />
          <br />
          {data.HeadInfo.paragraph2}
        </p>
      </div>
    </div>
  );
};

const Home = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('Home'));
  }, []);

  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.home}>
            <HeadInfo data={data} />
            <h2>{data.Fue.title}</h2>
            <div className={style.content}>
              <div className={style.column}>
                <div className={style.title}>
                  <a href="/fue-sac-ekimi#manuel">{data.Fue.subTitle1}</a>

                  <a href="/fue-sac-ekimi#micromotor">{data.Fue.subTitle2}</a>
                </div>

                <p>
                  {data.Fue.paragraph1}
                  <br />
                  <br />
                  {data.Fue.paragraph2}
                  <br />
                  <br />
                  {data.Fue.paragraph3}
                  <br />
                  <br />
                  {data.Fue.paragraph4}
                  <br />
                  <br />
                  {data.Fue.paragraph5}
                  <br />
                  <br />
                  {data.Fue.paragraph6}
                  <br />
                  <br />
                  {data.Fue.paragraph7}
                </p>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};
export default Home;
