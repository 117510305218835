import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { ProtectedRoute } from './routes/ProtectedRoute';
import ScrollTop from './helpers/ScrollTop';

export const history = createBrowserHistory();

const App = () => {
  const [isLang, setLang] = useState(false);

  useEffect(async () => {
    const lang = await localStorage.getItem('language');

    if (!lang) {
      await localStorage.setItem('language', 'TR');
    }
    await setLang(true);
  }, []);

  if (!isLang) {
    return <></>;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          {routes.map(route => {
            return (
              <Route
                path={route.path}
                element={
                  route.protected ? (
                    <ProtectedRoute>{route.component}</ProtectedRoute>
                  ) : (
                    route.component
                  )
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
