import style from './contact.module.scss';
import { Header, FooterForm } from '../../components';
import ContactBackground from '../../assets/img/contact-background.jpeg';

const Contact = () => {
  return (
    <>
      <Header />
      <div className={style.contact}>
        <div className={style.wrapper}>
          <img src={ContactBackground} alt="head" />

          <div className={style.iframeWrapper}>
            <iframe
              src="https://yandex.com.tr/map-widget/v1/-/CCUBVEVjSB"
              frameborder="1"
              allowfullscreen="true"
              title="contact-map"
            />
          </div>

          <div className={style.cardWrapper}>
            <div className={style.card}>
              <h4>Adres</h4>
              <p>
                Atakent Acıbadem Hastanesi
                <br /> Halkalı Merkez, Turgut Özal
                <br /> Bulvari No:16, 34303
                <br /> Küçükçekmece/İstanbul
              </p>
            </div>
            <a
              target={'_blank'}
              rel="noreferrer"
              href="https://wa.me/905331468684"
              className={style.card}
            >
              <h4>WhatApp İletişim</h4>
              <p>0533 146 86 84</p>
            </a>
            <a
              href="https://www.instagram.com/s.smed_hairtransplant"
              target={'_blank'}
              rel="noreferrer"
              className={style.card}
            >
              <h4>Instagram</h4>
              <p>s.smed_hairtransplant</p>
            </a>
          </div>
        </div>
      </div>
      <FooterForm />
    </>
  );
};

export default Contact;
