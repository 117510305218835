import style from './sakalekimi.module.scss';
import { FooterForm, Header } from '../../components';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';
import SakalEkimiImage from '../../assets/img/sakal_ekimi.jpg';

const SakalEkimi = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('SakalEkimi'));
  }, []);
  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.sakalEkimi}>
            <div className={style.wrapper}>
              <div className={style.card}>
                <img src={SakalEkimiImage} alt="kas" />
                <h1>
                  {data.title1} <br />
                  {data.title2}
                </h1>
                <p>
                  {data.paragraph1}
                  <br />
                  <div className={style.space} />
                  {data.paragraph2}
                  <br />
                  <div className={style.space} />
                  {data.paragraph3}
                </p>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default SakalEkimi;
