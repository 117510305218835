export const MenuData = value => {
  return [
    {
      title: [value.Header.homepage],
      link: '/',
      subMenu: [],
    },
    {
      title: [value.Header.about1, value.Header.about2],
      link: '/hakkimizda',
      subMenu: [],
    },
    {
      title: [value.Header.aboutTransplant1, value.Header.aboutTransplant2],
      link: '/sac-dokulmesi-nedenleri',
      subMenu: [
        {
          title: [value.Header.hairLoss],
          link: '/sac-dokulmesi-nedenleri',
          subMenu: [],
        },
        {
          title: [value.Header.medicalTreatment],
          link: '/tibbi-tedavi',
          subMenu: [],
        },
        {
          title: [value.Header.fue],
          link: '/fue-sac-ekimi',
          subMenu: [
            {
              title: value.Header.fueInner.manuel,
              link: '/fue-sac-ekimi#manuel',
            },
            {
              title: value.Header.fueInner.micromotor,
              link: '/fue-sac-ekimi#micromotor',
            },
            {
              title: value.Header.fueInner.unshaved,
              link: '/fue-sac-ekimi#unshaved',
            },
            {
              title: value.Header.fueInner.leteral,
              link: '/fue-sac-ekimi#leteral',
            },
            {
              title: value.Header.fueInner.dhi,
              link: '/fue-sac-ekimi#dhi',
            },
            {
              title: value.Header.fueInner.sedasyon,
              link: '/fue-sac-ekimi#sedasyon',
            },
          ],
        },
        {
          title: [value.Header.eyebrow],
          link: '/kas-ekimi',
          subMenu: [],
        },
        {
          title: [value.Header.beard],
          link: '/sakal-biyik-ekimi',
          subMenu: [],
        },
        {
          title: [value.Header.postOperation],
          link: '/operasyon-sonrasi-talimatlar',
          subMenu: [],
        },
      ],
    },
    {
      title: [value.Header.clinic],
      link: '/klinik',
      subMenu: [],
    },
    {
      title: [value.Header.onlineReservation1, value.Header.onlineReservation2],
      link: '/online-rezervasyon',
      subMenu: [],
    },
    {
      title: [value.Header.contact],
      link: '/iletisim',
      subMenu: [],
    },
    {
      title: [value.Header.institutional],
      link: '/kurumsal',
      subMenu: [],
    },
  ];
};
