import style from './footerform.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FooterLogo from '../../assets/img/logo/footer-logo.png';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';
import axios from 'axios';
import { BackendUrl } from '../../constants/ApiConfig';
import Popup from '../Popup/Popup';
import { useNavigate } from 'react-router-dom';

const lang = localStorage.getItem('language');

const SignupSchema = Yup.object().shape({
  nameSurname: Yup.string()
    .min(
      3,
      lang === 'TR'
        ? 'Kısa isim'
        : lang === 'ARB'
        ? `اسم قصير`
        : lang === 'EN'
        ? 'Short name'
        : 'Nombre corto'
    )
    .max(
      100,
      lang === 'TR'
        ? 'Uzun isim'
        : lang === 'ARB'
        ? `اسم طويل`
        : lang === 'EN'
        ? 'Long name'
        : 'Nombre largo'
    )
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    ),
  nationality: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      3,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  phone: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      3,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  email: Yup.string()
    .email('Doğru email giriniz')
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    ),
  subject: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      5,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  message: Yup.string()
    .required(
      lang === 'TR'
        ? 'Zorunlu alan'
        : lang === 'ARB'
        ? `يتطلب حقلا`
        : lang === 'EN'
        ? 'Required field'
        : 'Campo requerido'
    )
    .min(
      10,
      lang === 'TR'
        ? 'Kısa bilgi'
        : lang === 'ARB'
        ? `وصف قصير`
        : lang === 'EN'
        ? 'Short description'
        : 'Breve descripción'
    ),
  // terms: Yup.boolean()
  //   .required('Lütfen kullanım koşullarını kabul edin.')
  //   .oneOf([true], 'Lütfen kullanım koşullarını kabul edin.'),
});

const ClientForm = ({ data, formValue }) => {
  return (
    <div className={style.formWrapper}>
      <h2>{data.title}</h2>
      <Formik
        initialValues={{
          nameSurname: '',
          email: '',
          phone: '',
          nationality: '',
          subject: '',
          message: '',
          // terms: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={values => {
          formValue(values);
        }}
      >
        {({ errors, touched, values }) => (
          <Form className={style.form}>
            <label className={style.input} htmlFor={'nameSurname'}>
              <h5>{data.name}</h5>
              <Field
                id={'nameSurname'}
                type="text"
                name={'nameSurname'}
                placeholder={data.name}
                value={values.nameSurname}
              />
            </label>
            {errors.nameSurname && touched.nameSurname ? (
              <span className={style.error}>{errors.nameSurname} </span>
            ) : null}

            <label className={style.input} htmlFor={'email'}>
              <h5>{data.email}</h5>
              <Field
                id={'email'}
                type="email"
                name={'email'}
                placeholder={data.email}
                value={values.email}
              />
            </label>
            {errors.email && touched.email ? (
              <span className={style.error}>{errors.email} </span>
            ) : null}

            <label className={style.input} htmlFor={'phone'}>
              <h5>{data.phone}</h5>
              <Field
                id={'phone'}
                type="tel"
                name={'phone'}
                placeholder={data.phone}
                value={values.phone}
              />
            </label>
            {errors.phone && touched.phone ? (
              <span className={style.error}>{errors.phone} </span>
            ) : null}

            <label className={style.input} htmlFor={'nationality'}>
              <h5>{data.nationality}</h5>
              <Field
                id={'nationality'}
                type="text"
                name={'nationality'}
                placeholder={data.nationality}
                value={values.nationality}
              />
            </label>
            {errors.nationality && touched.nationality ? (
              <span className={style.error}>{errors.nationality} </span>
            ) : null}

            <label className={style.input} htmlFor={'subject'}>
              <h5>{data.subject}</h5>
              <Field
                id={'subject'}
                type="text"
                name={'subject'}
                placeholder={data.subject}
                value={values.subject}
              />
            </label>
            {errors.subject && touched.subject ? (
              <span className={style.error}>{errors.subject} </span>
            ) : null}

            <label className={style.textarea} htmlFor={'message'}>
              <h5>{data.message}</h5>
              <Field
                as="textarea"
                id={'message'}
                name={'message'}
                placeholder={data.message}
                value={values.message}
              />
            </label>
            {errors.message && touched.message ? (
              <span className={style.error}>{errors.message} </span>
            ) : null}
            <div className={style.submitWrapper}>
              {/* <label className={style.checkbox} htmlFor={'terms'}>
                <Field id={'terms'} name={'terms'} type="checkbox" />
                <span>Koşulları kabul edin</span>
              </label>
              {errors.terms && touched.terms ? (
                <span className={style.error}>{errors.terms} </span>
              ) : null} */}
              <button className={style.submit} type="submit">
                {data.send}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const FooterForm = () => {
  const [data, setData] = useState();
  const [isOpen, setOpen] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setData(getLanguageData('FooterForm'));
  }, []);

  const sendContactForm = value => {
    axios
      .post(`${BackendUrl}/contact/createContact`, value)
      .then(res => {
        console.log(res.data);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <>
      {isOpen ? (
        <Popup
          click={() => {
            setOpen(false);
            navigate('/');
          }}
          isLoad={isLoad}
        />
      ) : null}
      {data ? (
        <div className={style.footerForm}>
          <div className={style.logo}>
            <img src={FooterLogo} alt="logo" />
            <h4>
              Atakent Acıbadem Hastanesi Halkalı Merkez, Turgut Özal Bulvari
              No:16, 34303 Küçükçekmece/İstanbul
            </h4>
          </div>
          <ClientForm
            formValue={e => {
              let newValues = {
                ...e,
                language: localStorage.getItem('language'),
              };
              setOpen(true);
              setLoad(true);
              sendContactForm(newValues);
            }}
            data={data}
          />
        </div>
      ) : null}
    </>
  );
};

export default FooterForm;
