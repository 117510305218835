import { Header, FooterForm } from '../../components';
import style from './sacdokulmesitedavileri.module.scss';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';
import SacMezoterapi from '../../assets/img/sactedavisi/sac-mezoterapi.jpg';
import FinansteridImage from '../../assets/img/sactedavisi/finansterid.jpg';
import HeadImage from '../../assets/img/sactedavisi/head.jpg';

const SacDokulmesiTedavileri = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('SacDokulmesiTedavileri'));
  }, []);
  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.sacDokulmesiTedavileri}>
            <div className={style.wrapper}>
              <div
                style={{
                  backgroundImage: `url(${HeadImage})`,
                }}
                className={style.headCard}
              >
                <h1>
                  {data.title1} <br />
                  {data.title2}
                </h1>
                <h2>{data.subInfo}</h2>
              </div>
              <div className={style.paragraphWrapper}>
                <div className={style.paragraph}>
                  <div className={style.column}>
                    <h2> {data.subTitle1}</h2>
                    <p>
                      <div className={style.space} />
                      {data.paragraph1}
                      <br />
                    </p>

                    <p>
                      <div className={style.space} />

                      {data.paragraph2}
                    </p>
                  </div>
                  <div className={style.column}>
                    <h2> </h2>
                    <p>
                      {data.paragraph3}
                      <div className={style.space} />
                    </p>
                    <p>{data.paragraph4}</p>
                  </div>
                </div>
              </div>
              <div className={style.paragraphWrapperWithImage}>
                <div className={style.paragraph}>
                  <div
                    style={{
                      alignItems: 'flex-start',
                    }}
                    className={style.column}
                  >
                    <h2>{data.subTitle2}</h2>
                    <p>
                      {data.paragraph5}
                      <div className={style.space} />
                      <br />
                    </p>
                    <p>
                      {data.paragraph6}
                      <div className={style.space} />
                      <br />
                    </p>
                    <p>{data.paragraph7}</p>
                  </div>
                  <div className={style.column}>
                    <img src={SacMezoterapi} alt="cover" />
                    <div className={style.backCover} />
                  </div>
                </div>
              </div>
              <div
                className={`${style.paragraphWrapperWithImage} ${style.rowReverse}`}
              >
                <div className={style.paragraph}>
                  <div
                    style={{
                      alignItems: 'flex-start',
                    }}
                    className={style.column}
                  >
                    <h2>{data.subTitle3}</h2>
                    <p>
                      {data.paragraph8}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph9}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph10}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph11}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph12}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph13}
                      <br />
                      <br />
                    </p>
                    <p>
                      {data.paragraph14}
                      <br />
                      <br />
                    </p>
                    <h2>{data.subTitle4}</h2>
                    <p>
                      {data.paragraph15}
                      <br />
                      <br />
                    </p>
                  </div>
                  <div className={style.column}>
                    <img src={FinansteridImage} alt="cover" />
                    <div className={style.backCover} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default SacDokulmesiTedavileri;
