import { InstagramIcon } from '../../assets/icons';
import ConstructionImage from '../../assets/img/construction-head-image.png';
import Logo from '../../assets/img/logo.png';
import style from './underconstraction.module.scss';

const UnderConstraction = () => {
  return (
    <div className={style.underConstraction}>
      <header className={style.header}>
        <a className={style.logo} href="/">
          <img src={Logo} alt="construction" />
        </a>
        <a
          href="https://www.instagram.com/s.smed_hairtransplant/"
          rel="noreferrer"
          target={'_blank'}
          className={style.socialMedia}
        >
          <div className={style.icon}>
            <div className={`${style.line} ${style.lineLeft}`}></div>
            <InstagramIcon />
            <div className={`${style.line}`}></div>
          </div>
          <span>s.smed_hairtransplant</span>
        </a>
      </header>
      <div className={style.content}>
        <div className={style.headImage}>
          <img src={ConstructionImage} alt="construction" />
          <h1>
            Sizlere daha iyi hizmet verebilmek <br /> adına sitemizi yeniliyoruz
          </h1>
        </div>
        <div className={style.text}>
          <h2>S&S MED HAKKINDA</h2>
          <div className={style.paragraph}>
            <p>
              S&S MED, sağlık ve saç ekimi alanındaki tecrübelerini birleştirmiş
              deneyimli uzman ekipleri ile anlaşmalı olduğu Atakent Acıbadem
              Hastanesi’nde hasta memnuniyeti odaklı hizmet sunan bir sağlık
              kuruluşudur.
              <br />
              <br />
              Merkezimizin temel prensibi, uzman ekimiz tarafından saç ekimi ve
              saç dökülmesi tedavileri alanında uluslararası arenadaki
              gelişmeler sürekli takip edilerek, etik kurallar çerçevesinde ekim
              yaptıracak kişinin özelliklerine göre tespit ve değerlendirme
              yapılarak, hasta için en uygun çözümün uygulanmasıdır.
              <br />
              <br />
              Temel amacımız, optimum saç ekimi ile dolgun ve doğal bir görünüm
              yaratmaktır.
            </p>
            <p>
              Alanında uzman kadromuz, her hastanın özelliğine ve tercihlerine
              göre farklı yöntem ve teknikleri uygulayabilmektedir.
              <br />
              <br />
              Uzmanlarımız gerek ulusal gerekse uluslararası kongrelere (ISHRS
              2. Akdeniz Fue Workshop Madrid/İspanya 24-26 Mayıs 2013, ISHRS
              Avrupa Saç Ekim Semineri Brüksel/Belçika 13-15 Haziran 2014, 3.
              Akdeniz FUE Workshop İstanbul/Türkiye 25-27 Haziran 2015), katılım
              sağlamış ve etkin olarak görev almıştır. Saç nakli cerrahisi
              alanında 13 yıllık deneyim ve tecrübesiyle hizmet vermeye devam
              eden uzmanlarımız bu süreçte plastik cerrahlar eşliğinde katıldığı
              binlerce saç ekimi operasyonunun yanında kaş, bıyık ve sakal
              nakilleri operasyonlarına da katılmıştır.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstraction;
