// import { InstagramIcon } from '../../assets/icons';
import ConstructionImage from '../../assets/img/construction-head-image.png';
import style from './about.module.scss';
import { FooterForm, Header } from '../../components';
import { getLanguageData } from '../../data/Language';
import { useEffect, useState } from 'react';

const About = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('About'));
  }, []);

  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.about}>
            <div className={style.content}>
              <div className={style.headImage}>
                <img src={ConstructionImage} alt="construction" />
              </div>
              <div className={style.text}>
                <h2>{data.title}</h2>
                <div className={style.paragraph}>
                  <p>
                    {data.paragraph1}
                    <br />
                    <div className={style.space} />
                    {data.paragraph2}
                    <br />
                    <div className={style.space} />

                    {data.paragraph3}
                  </p>
                  <p>
                    {data.paragraph4}
                    <br />
                    <div className={style.space} />

                    {data.paragraph5}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default About;
