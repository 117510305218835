import { Header, FooterForm } from '../../components';
import style from './operasyonsonrasitalimatlar.module.scss';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';
const OperasyonSonrasiTalimatlar = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(getLanguageData('OperasyonSonrasi'));
  }, []);
  return (
    <>
      {data ? (
        <>
          <Header />
          <div className={style.operasyonSonrasiTalimatlar}>
            <div className={style.wrapper}>
              <div className={style.headCard}>
                <h1>
                  {data.title1} <br />
                  {data.title2} <br />
                  {data.title3} <br />
                  {data.title4}
                </h1>
              </div>
              <div className={style.listWrapper}>
                <ul>
                  {data.list1.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
                <ul>
                  {data.list2.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
              <h2> {data.title5}</h2>

              {/* <div
                style={{
                  backgroundImage: `url(https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940`,
                }}
                className={style.imageCard}
              >
              </div> */}
              <div className={style.listWrapper}>
                <ul>
                  {data.list3.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
                <ul>
                  {data.list4.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
              <h2>{data.title6}</h2>

              {/* <div
                style={{
                  backgroundImage: `url(https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940`,
                }}
                className={style.imageCard}
              >
              </div> */}
              <div className={style.listWrapper}>
                <ul>
                  {data.list5.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
                <ul>
                  {data.list6.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
          <FooterForm />
        </>
      ) : null}
    </>
  );
};

export default OperasyonSonrasiTalimatlar;
