import style from './clinic.module.scss';
import ConstructionImage from '../../assets/img/construction-head-image.png';
import { FooterForm, Header } from '../../components';
import ContactBackground from '../../assets/img/contact-background.jpeg';
const Clinic = () => {
  return (
    <>
      <Header />
      <div className={style.clinic}>
        <div className={style.wrapper}>
          <img src={ConstructionImage} alt="head" />
          <img src={ContactBackground} alt="head" />
        </div>
      </div>
      <FooterForm />
    </>
  );
};

export default Clinic;
