import style from './header.module.scss';
import Logo from '../../assets/img/logo/footer-logo.png';
import {
  WhatsappIcon,
  // MessengerIcon,
  // FacebookIcon,
  // YoutubeIcon,
  InstagramLogoIcon,
  HamburgerMenuIcon,
  CloseIcon,
} from '../../assets/icons';
import { MenuData } from '../../data/MenuData';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLanguageData } from '../../data/Language';
import Helmet from 'react-helmet';

const tagReturn = value => {
  return {
    lang: value === 'ARB' ? 'ar' : value.toLowerCase(),
    dir: value === 'ARB' ? 'rtl' : 'ltr',
  };
};

const keywordTags = lang => {
  const espTags = [
    'Transplante capilar',
    'Trasplante de pelo',
    'Trasplante capilar',
    'Alopecia',
    'Finasteride',
    'Minoxidil',
    'Calvicie',
    'Pelo',
    'Cabello',
    'Turquia',
    'Estambul',
    'İnjerto capilar',
    'Metodo fue',
    'İmplante capilar',
    'İmplante de pelo',
    'Graft',
    'Foliculo',
    'İnjerto',
    'Micromotor',
    'Tratamiento capilar',
    'Peinado',
    'Norwood',
    'coronilla',
  ];
  const trTags = [
    'FUE',
    'Uzun FUE',
    'Traşsız FUE',
    'Manuel FUE',
    'Uzun Manuel FUE',
    'Traşsız Manuel FUE',
    'Saç Ekimi',
    'Manuel Saç Ekimi',
    'FUE Saç Ekimi',
    'Traşsız Saç Ekimi',
    'Saç Nakli',
    'Saç Ekimi Maliyetleri',
    'Saç Ekim Merkezi/leri',
    'Doğal görünümlü Saç',
    'Sakal Ekimi',
    'Bıyık Ekimi',
    'Kaş Ekimi',
    'Saç Ekim Yöntemleri',
    'Saç Dökülmesi ve Nedenleri',
    'Erkeklerde Saç Dökülmesi',
    'Kadınlarda Saç Dökülmesi',
    'Saç Ekimi Fiyatları/Maliyetleri',
    'Saç Ektirme Sonrası',
    'Saç Ekimi İstanbul',
    'Saç Ekimi Türkiye',
    'Greft',
    'Donör',
    'Manuel Punch',
    'Finasterid',
    'Folükül',
    'Saç Kökü',
  ];
  const enTags = [
    'Hairtransplant',
    'Hairloss',
    'Hair transplant',
    'Alopecia',
    'Baldness',
    'Graft',
    'FUE',
    'İstanbul',
    'Turkey',
    'Follicle',
    'Scalp',
    'Minoxidil',
    'Finasteride',
    'FUE technique',
    'Norwood',
    'Hairstyle',
    'Frontline',
    'Vertex',
  ];

  if (lang === 'EN') {
    return enTags;
  } else if (lang === 'ESP') {
    return espTags;
  } else {
    return trTags;
  }
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentLocation = location.pathname;
  const [currentLanguage, setCurrentLanguage] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    let getLanguage = localStorage.getItem('language');
    if (!getLanguage) {
      localStorage.setItem('language', 'TR');
      setCurrentLanguage('TR');
    } else {
      setCurrentLanguage(getLanguage);
    }
  }, []);

  const [isOpen, setOpen] = useState(false);

  const languages = ['TR', 'EN', 'ESP', 'ARB'];

  useEffect(() => {
    setData(getLanguageData('Global'));
  }, []);

  if (data && currentLanguage) {
    console.log(
      [...MenuData(data).map(item => item.subMenu), ...MenuData(data)]
        .flat()
        .filter(item => item.link === currentLocation)[0].title
    );
  }

  return (
    <>
      {data && currentLanguage ? (
        <>
          <Helmet htmlAttributes={tagReturn(currentLanguage)}>
            <title>{`S&S Med - ${[
              ...MenuData(data).map(item => item.subMenu),
              ...MenuData(data),
            ]
              .flat()
              .filter(item => item.link === currentLocation)[0]
              .title.join(' ')}`}</title>

            <meta
              name="keywords"
              content={keywordTags(currentLanguage).map(i => i)}
            />
          </Helmet>

          <header
            className={`${style.header} ${
              currentLanguage === 'ARB' ? style.arbHeader : null
            }`}
          >
            <div className={style.wrapper}>
              <a href="/" className={style.logo}>
                <img src={Logo} alt="logo" />
              </a>
              <div className={style.contentWrapper}>
                <div className={style.top}>
                  <div className={style.socialMedia}>
                    {/* <a href="/">
                    <MessengerIcon />
                  </a> */}
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://wa.me/905331468684"
                    >
                      <WhatsappIcon />
                    </a>
                    {/* <a href="/">
                    <FacebookIcon />
                  </a> */}
                    <a
                      href="https://www.instagram.com/s.smed_hairtransplant"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <InstagramLogoIcon />
                    </a>
                    {/* <a href="/">
                    <YoutubeIcon />
                  </a> */}
                  </div>
                  <ul className={style.language}>
                    {languages.map(item => {
                      return (
                        <li
                          className={
                            currentLanguage === item ? style.selected : null
                          }
                        >
                          <button
                            onClick={() => {
                              localStorage.setItem('language', item);
                              navigate(0);
                            }}
                          >
                            {item}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <ul className={style.bottom}>
                  {MenuData(data).map(item => {
                    const isCurrentLink = currentLocation === item.link;
                    return (
                      <li className={isCurrentLink ? style.selected : null}>
                        <a href={item.link}>
                          {item.title.map(text => (
                            <>
                              {text} <br />
                            </>
                          ))}
                        </a>
                        {item.subMenu.length ? (
                          <div className={`${style.subMenu} `}>
                            {item.subMenu.map(subItem => {
                              const isCurrentLink =
                                currentLocation === subItem.link;

                              return (
                                <>
                                  <div
                                    className={`${
                                      isCurrentLink ? style.selected : null
                                    } ${style.subMenuWrapper} ${
                                      subItem.subMenu.length
                                        ? style.canHover
                                        : null
                                    }`}
                                  >
                                    <a
                                      className={`${
                                        subItem.subMenu.length
                                          ? style.canHover
                                          : null
                                      }`}
                                      href={subItem.link}
                                    >
                                      {subItem.title}
                                    </a>
                                  </div>
                                  {subItem.subMenu.length ? (
                                    <div className={style.subInnerWrapper}>
                                      {subItem.subMenu.map(subItemInner => {
                                        const isCurrentLink =
                                          currentLocation === subItemInner.link;

                                        return (
                                          <div
                                            className={`${
                                              isCurrentLink
                                                ? style.selected
                                                : null
                                            } ${style.subInner}`}
                                          >
                                            <a href={subItemInner.link}>
                                              {subItemInner.title}
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <button
                onClick={() => setOpen(true)}
                className={style.hamburgerMenu}
              >
                <HamburgerMenuIcon />
              </button>

              {isOpen ? (
                <div
                  className={`${style.mobileMenu} ${
                    localStorage.getItem('language') === 'ARB'
                      ? style.arb
                      : null
                  }`}
                >
                  <button
                    onClick={() => setOpen(false)}
                    className={style.close}
                  >
                    <CloseIcon />
                  </button>
                  <ul onClick={() => setOpen(false)} className={style.menu}>
                    {MenuData(data).map(item => {
                      const isCurrentLink = currentLocation === item.link;
                      return (
                        <li className={isCurrentLink ? style.selected : null}>
                          <a className={style.head} href={item.link}>
                            {item.title.map(text => (
                              <>{text} </>
                            ))}
                          </a>
                          {item.subMenu.length &&
                          item.subMenu
                            .map(i => i.link)
                            .includes(currentLocation) ? (
                            <div className={style.subMenu}>
                              {item.subMenu.map(subItem => {
                                const isCurrentLink =
                                  currentLocation === subItem.link;
                                return (
                                  <>
                                    <div
                                      className={
                                        isCurrentLink
                                          ? style.selectedBottom
                                          : null
                                      }
                                    >
                                      <a
                                        className={style.bottom}
                                        href={subItem.link}
                                      >
                                        {subItem.title}
                                      </a>
                                    </div>
                                    {subItem.subMenu.length ? (
                                      <div className={style.subInnerWrapper}>
                                        {subItem.subMenu.map(subItemInner => {
                                          const isCurrentLink =
                                            currentLocation ===
                                            subItemInner.link;

                                          return (
                                            <div
                                              className={`${
                                                isCurrentLink
                                                  ? style.selected
                                                  : null
                                              } ${style.subInner}`}
                                            >
                                              <a href={subItemInner.link}>
                                                {subItemInner.title}
                                              </a>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                  <div className={style.bottom}>
                    <div className={style.socialMedia}>
                      {/* <a href="/">
                    <MessengerIcon />
                  </a> */}
                      <a
                        target={'_blank'}
                        rel="noreferrer"
                        href="https://wa.me/905331468684"
                      >
                        <WhatsappIcon />
                      </a>
                      {/* <a href="/">
                    <FacebookIcon />
                  </a> */}
                      <a
                        href="https://www.instagram.com/s.smed_hairtransplant"
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        <InstagramLogoIcon />
                      </a>
                      {/* <a href="/">
                    <YoutubeIcon />
                  </a> */}
                    </div>
                    <ul className={style.language}>
                      {languages.map(item => {
                        return (
                          <li
                            className={
                              currentLanguage === item ? style.selected : null
                            }
                          >
                            <button
                              onClick={() => {
                                localStorage.setItem('language', item);
                                navigate(0);
                              }}
                            >
                              {item}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </header>
        </>
      ) : null}
    </>
  );
};

export default Header;
